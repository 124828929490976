import { _User } from '../core/user.model';
import { ShoppingCart } from './cart.model';
import { PickupLocation } from './pickup-location.model';
import { EventEmitter } from '@angular/core';
import { ShippingType } from './shipping-settings.model';

// DB collections:
// products (Product)
// files (StoredFile)
// orders (Order)
// temporaryUploads (PrintFile)
// cart (ShoppingCart)
// settings.nextOrderNumber (id)

// products (unique copies, bindings and quick access products)

export class OldProduct {  // collection
  createdAt?: Date;
  updatedAt?: Date;
  type: ProductType;
  /**
   *  whether it's visible in website (copies won't be, binding neither)
   */
  visible: boolean;
  title: string;
  description: string;
  /**
   * page price? encuadernaciones dónde van? (otro producto?) // includes tax
   */
  unitPrice: Price;
  pictureUrl?: string;
  picturePath?: string;
  weight: number;
}

export class Price {
  amt: number; // amount
  cur: string; // currency
  amtWithTax?: number;
}

export enum PaperWeight { W80 = '80', W90 = '90', W100 = '100', W120 = '120', W160 = '160', W200 = '200', W300 = '300' }

export enum LaminationType { Matte = 'matte', Gloss = 'gloss' }

export enum LaminationWeight { W80 = '80', W125 = '125', W150 = '150', W175 = '175', W250 = '250' }

export enum Settings {
  PAGE_SIZE = "pageSize",
  PAPER_WEIGHT = "paperWeight",
  PAGE_ORIENTATION = "pageOrientation",
  COLOR = "color",
  PAGES_PER_SHEET = "pagesPerSheet",
  TWO_SIDED = "twoSided",
  PAGE_TURN = "pageTurn",
  FINISHING = "finishing",
  GROUPED = "grouped",
  COVER_COLOR = "coverColor",
  COVER_LAMINATED = "coverLaminated",
  RING_COLOR = "ringColor",
  HARD_COVER_FRONT = "hardCoverFront", // new
  HARD_COVER_BACK = "hardCoverBack",  // new
  DOC_COLOR = 'docColor',
  AUTO_ROTATE = 'autoRotate',
  SKIP_FIT_TO_PAGE = 'skipFitToPage',
  LAMINATION_WEIGHT = 'laminationWeight',
  LAMINATION_TYPE = 'laminationType'
}

export enum SettingCategory {
  BASIC = 'basic',
  BINDING = 'binding',
  OTHERS = 'others',
  SPECIAL = 'special'
}

export const SettingCategoryText: Record<SettingCategory, string> = {
  [SettingCategory.BASIC]: 'Acabados básicos',
  [SettingCategory.BINDING]: 'Tipos de encuadernados',
  [SettingCategory.OTHERS]: 'Otros acabados',
  [SettingCategory.SPECIAL]: 'Acabados especiales'
};

export class PrintSettings {
  //copies: number; // total copies of this same document. > 0. default: 1
  [Settings.PAGE_SIZE]: PageSize;  // default: A4
  [Settings.PAPER_WEIGHT]: PaperWeight;
  [Settings.PAGE_ORIENTATION]: PageOrientation;  // default: landscape
  [Settings.COLOR]: PrintColor;  // default: bn
  [Settings.PAGES_PER_SHEET]: PagesPerSheet; // default: 1
  [Settings.TWO_SIDED]: boolean; // print on both sides. default: true
  [Settings.AUTO_ROTATE]: boolean; // print on both sides. default: true
  [Settings.PAGE_TURN]: PageTurn;
  [Settings.FINISHING]: Finishing;
  [Settings.GROUPED]: boolean;
  [Settings.COVER_COLOR]: boolean;
  [Settings.COVER_LAMINATED]: boolean;
  [Settings.RING_COLOR]: any;
  [Settings.HARD_COVER_FRONT]: any;
  [Settings.HARD_COVER_BACK]: any;
  [Settings.DOC_COLOR]: boolean;
  [Settings.SKIP_FIT_TO_PAGE]: boolean;
  [Settings.LAMINATION_TYPE]: LaminationType;
  [Settings.LAMINATION_WEIGHT]: LaminationWeight;
}

export enum CoverType {
  Color1Side1Page = "Color1Side1Page",
  Color1Side2Page = "Color1Side2Page",
  Color1Side4Page = "Color1Side4Page",
  Color2Side1Page = "Color2Side1Page",
  Color2Side2Page = "Color2Side2Page",
  Color2Side4Page = "Color2Side4Page",
  Black1Side1Page = "Black1Side1Page",
  Black2Side1Page = "Black2Side1Page",
  Black1Side2Page = "Black1Side2Page",
  Black2Side2Page = "Black2Side2Page",
  Black1Side4Page = "Black1Side4Page",
  Black2Side4Page = "Black2Side4Page",
}


export class StoredFile { // collection
  createdAt: Date;
  updatedAt: Date;
  name: string; // file name, as uploaded
  contentType: string; // TODO: does client has this? it comes from object once uploaded
  // these come from Storage Upload Response:
  path: string; // file path, reference to object in storage (files/<order id>/<printing group>/<file id>/<file name>)
  size: number; // file size, in bytes
  url: string; // download url
}

export class _StoredFile { // collection
  name: string; // file name, as uploaded
  // these come from Storage Upload Response:
  contentType: string; // TODO: does client have this? it comes from object once uploaded
  path: string; // file path, reference to object in storage (files/<order id>/<printing group>/<file id>/<file name>)
  size: number; // file size, in bytes
  url: string; // download url
}

export class PrintFile { // collection
  createdAt?: Date;
  updatedAt?: Date;
  // 2 docs: original and transformed. only original exists if PDF. reference is for print work:
  originalFile: _StoredFile; // file as originally uploaded
  originalType: FileType; // just for the icon
  printFile: _StoredFile; // transformed file (if PDF, it's a reference to the same stored document)
  pages: number; // pdf total pages, no matter printer settings
  thumbFile: _StoredFile; // 200px thumbnail
  status: PrintFileStatus;
  errorMessage?: string;
  orderId?: string;  // reference to order using this file (esto lo usa el backend nomás)
  substatus?: PrintFileSubStatus; // sub-status while in-process
  thumbs?: Array<ThumbSetting>;
  printProtected?: boolean;
  printAllowed?: boolean;
  pswProtected?: boolean;
}

export interface ThumbSetting {
  printSettings: Pick<PrintSettings, Settings.PAGE_ORIENTATION | Settings.COLOR | Settings.COVER_COLOR | Settings.PAGES_PER_SHEET | Settings.AUTO_ROTATE | Settings.PAGE_TURN>,
  file: _StoredFile,
}

export class _PrintFile extends PrintFile {
  id: string; // reference to print file document
  coverColor?: boolean;
  coverLaminated?: boolean;
  docColor?: boolean;
  binding?: BindingSummary;
  blankSheetsBefore?: number;
  blankSheetsAfter?: number;
  printSheets?: number;
  alias?: string;
  ringColor?: any;
  hardCoverFront?: any;
  hardCoverBack?: any
  coverType?: {
    coverColor: boolean;
    pagesPerSheet: PagesPerSheet;
    id: string;
    twoSided: boolean,
    paperWeight: PaperWeight,
    paperWeightChanged?: PaperWeight
    default?: boolean
  };
  laminationType?: LaminationType;
  laminationWeight?: LaminationWeight
}

export interface BindingSummary {
  rings?: any[];
  covers?: any[];
  count: number;
  ringSplit: boolean;
  ringDiameter: number;
  weight: number;
}

export class PrintingGroup {  // collection x 2
  printSettings: PrintSettings;
  //finishing: Finishing;
  comment: string;
  files: _PrintFile[];
  // MN: total pages (sides) to be printed: depends on pagesPerSheet
  printSides?: number; // amount of pages to be printed, for this group (página o cara del folio, es el total de impresiones)
  printSheets?: number; // amount of sheets to be used, for this group (folio, a 1 o 2 caras)
  binding?: BindingSummary;
  weight?: number;
}


/**
 * Message sended by Output() actions
 * //TODO movido a output.model, quitar este
 */
export class OutputMessage {
  func: string;
  value?: any;

  constructor(type, value) {
    this.func = type,
      this.value = value
  }

  invoke = (_this) => {
    // console.log(this,_this[this.type]);
    if (!_this[this.func]) console.error("Función no encontrada", this);
    if (this.value) _this[this.func](this.value);
    else _this[this.func]();
  }
}

export interface ReceptorMessage {
  onAction(event: OutputMessage): void;
}

export interface EmmiterMessage {
  action: EventEmitter<OutputMessage>;

  emitAction(type: string, value?): void;
}

export type FeeType = 'share' | 'referrer' | 'coupon' | 'creator' | 'royalty';

export interface Fees {
  share: number;
  referrer: number;
  coupon: number;
  creator: number;
  royalty: number;
}

export class FullPrice {
  cur: string; // currency
  taxPct: number; // tax percentage (from const!)
  noTaxAmt: number; // amount previous to tax  (totalAmt/(1+taxPct/100))
  taxAmt: number; // amount representing the tax (noTaxAmt * taxPct/100)
  totalAmt: number; // total amount (original sum from products, those include tax)
}

export class PostalAddress {
  recipient: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  province: string; // spain province enum?
  provinceId: string; // New XA 24/sep/2020 //Update JA 2021/03/15 number -> string
  postalCode: string;
  country: string; // country ISO2. enum country definition?
  phone?: string;
  organization?: string;
  pickupPointName?: string;
  pickupPointCode?: string;
  shippingCourier?: string;
  taxId?: string;
  dni?: string;
}

export class Order {  // collection
  _version: number;
  _appVersion: string;
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  updatedBy?: UpdatedBy;
  // cartId: string; // reference to cart document
  number: number; // order number. this needs to be got from settings.nextOrderNumber
  name: string
  userId?: string; // reference to user document. may be anonymous
  email: string;  // user's email address
  phone?: string; // user's phone
  cart: ShoppingCart;
  paymentStatus: OrderPaymentStatus;
  processStatus: OrderProcessStatus;
  shippingStatus: OrderShippingStatus;
  shippingAddress: PostalAddress;
  billingAddress: PostalAddress;
  shippingType: ShippingType;
  pickupAddress?: PickupLocation.Base;
  paymentType: PaymentType;
  paymentService?: PaymentServiceType; // only if credit card, service used
  userComments?: string; // by user on payment
  // on reply from payment:
  paymentRef?: string; // payment reference from service or according to payment type
  paymentLog?: any[];  // push payment response here (array so failed + retry success can be tracked)
  // not to be set initially by client:
  adminComments?: string; // by admin from panel
  invoiceRef?: string;  // invoice reference accotding to API
  invoiceUrl?: string;
  transactionId?: string; // reference to payment transaction // NEW 2
  responsible?: _User;  // admin user responsible of processing this order  // NEW 2  

  policy?: boolean;
  ads?: boolean;
  printProtected?: boolean;
  shippingLabelId?: string;
  type: OrderType;
  linkedOrders?: Array<LinkedOrders>;
  acceptance?: any;
  hasReportIncident?: boolean; // boolean that indicates if the client has sent a report incident
  blockSharing?: boolean;
}

export class LinkedOrders {
  id: string; // order id
  number: number; // order number
}


/*
Falta: carrito. completar order.
registro individual de pagos? (full response)
*/

export class Discount {
  type: DiscountType;
  amount: number;
  currency: string;
  refId: string; // reference to entity generating this discount, according to type (userId, couponId)
  transactionRef?: DocReference; // reference to transaction generated by this discount
}

export class DocReference {
  id: string; // document id
  col: string; // collection
}

export interface ShippingLabel { //XA: New 12/oct/2020
                                 // on client create:
  status: ShippingLabelStatus,
  orderId: String,
  createdAt: Date | any,
  // on server update:
  errorMessage?: String,
  file: StoredFile,
  request: any,
  response: any,
  updatedAt: Date | any,

  //new Xav added
  tracking?: any,
}

export enum ShippingLabelStatus {PROCESSING = 'processing', SUCCESS = 'success', FAILED = 'failed'}

export interface BankInformation {
  account: string;
  holder: string;
  notifyEmail: string;
}

export interface UpdatedBy {
  id: string;
  displayName: string;
  from: UpdatedBy.From;
}

export interface TrackingData {
  minDeliveryDate: Date;
  maxDeliveryDate: Date;
  internalStatus: ShippingInternalStatus,
  url: string
}

export interface DeviceInfo {
  brands: string; // Marcas del navegador (si está disponible)
  mobile: boolean; // ¿Es un dispositivo móvil?
  platform: string; // Plataforma (Windows, Android, etc.)
  userAgent: string; // User-Agent completo
  screenResolution: string;
  viewportSize: string;
  cookiesEnabled: boolean;
  connectionType?: string; //tipo de conexion (4g,3g...)
  bandwidth?: string; //ancho de banda aproximado
}

export namespace UpdatedBy {
  export enum From {
    APP = 'app',
    ADMIN = 'admin',
    SERVER = 'server'
  }
}

export const TAX_RATE_PCT = 21;  // tax rate

export enum OrderType { REGULAR = 'regular', COMPLEMENTARY = 'complementary' }

export enum ShippingInternalStatus {
  DELIVERED = 'delivered',
  ONDELIVERY = 'ondelivery',
  RETURNED = 'returned',
  TRIED = 'tried',
  INPROGRESS = 'inprogress',
  PICKUP = 'pickup'
}

export enum ProductType { PRINT = 'print', STORE = 'store', WALLETBONUS = 'walletbonus', GENERIC = 'generic' }

export enum PageSize { A3 = 'A3', A4 = 'A4', A5 = 'A5' }

export enum PageOrientation { PORTRAIT = 'portrait', LANDSCAPE = 'landscape' }

export enum PrintColor { BLACKNWHITE = 'bn', COLOR = 'color' }

export enum PagesPerSheet { ONE = 1, TWO = 2, FOUR = 4 } // 6, 9, 16 
export enum PageTurn {LARGESIDE = 'largeside', SHORTSIDE = 'shortside' }

export enum Finishing {
  NONE = 'none',
  STAPLED = 'stapled',
  BINDING = 'binding',
  BINDINGX = 'bindingx',
  LAMINATEDA4 = 'laminatedA4',
  LAMINATEDA3 = 'laminatedA3',
  LAMINATED = 'laminated',
  PERFORATED2 = 'perforated2',
  PERFORATED4 = 'perforated4',
  PERFORATEDBIND = 'perforatedbind',
} // BINDINGX only for backward compatibility in old orders and old cart sharing
export enum FileType {
  PDF = 'pdf', WORD = 'word', EXCEL = 'excel', POWERPOINT = 'powerpoint',
  IMAGE = 'image', OTHER = 'other'
} // TODO: redefinir esto? o usar mime type? separar image type? se usará para icono generalmente. el mime type está en el StoredFile
export enum PrintFileStatus { NEW = 'new', PROCESSING = 'processing', FINISHED = 'finished', FAILED = 'failed' }


/// TODO: definir... credit card --> sub: redsys? stripe, etc?
export enum PaymentType {
  TRANSFER = 'transfer', BIZUM = 'bizum', CREDITCARD = 'creditcard', FULLBONUS = 'fullbonus',
  MONTHLYPAYMENT = 'monthlypayment'
}

export enum PaymentServiceType { REDSYS = 'redsys' } // stripe, etc...


export enum OrderPaymentStatus {
  PENDING = 'pending', // (step 1A) order entered from client when PaymentType=TRANSFER. waiting for admin to set it as paid
  PROCESSING = 'processing', // (step 1B) order is being processed by the payment gateway
  PAID = 'paid',  // order has been paid (from payment gateway reply, or from admin setting it as paid after bank transfer)
  FAILED = 'failed', // payment failed (from payment gateway reply, add error message to order)
  REFUNDED = 'refunded', // order has been partially or completelly refunded to client (only available after being PAID)
}

export enum OrderProcessStatus {
  PENDING = 'pending', // order is still pending to be processesed
  PROCESSING = 'processing', // order is being processed (set by admin, needs to add admin as 'processedBy')
  COMPLETE = 'complete',  // order is complete and ready to be dispatched
  CANCELED = 'canceled',  // order has been canceled and won't be processed
  SHIPPED = 'shipped', // Enviado
  RETURNED = 'returned',  // Devuelto
  PAUSED = 'paused', // En Espera
  ONHOLD = 'onhold', // NEW: paused by client (mhm... labels are swapped with paused)
  INCIDENCE = 'incidence',  // order has an incidence to be solved prior to shipping
}

//Xav Added 18/dic/2020
//TODO conversar con Xavier nombre de interface y ambiguedad del uso
export enum OrderTypeStatus {
  PROCESS = 'processStatus',
  SHIPPING = 'shippingStatus',
  PAYMENT = 'paymentStatus',
  PAYTYPE = 'paymentType',
  PIGTRANSTYPE = 'piggyTransactionType',
  PIGTRANSSTATUS = 'piggyTransactionStatus',
  PIGTRANSOPT = 'piggyTransactionOption'
}

export enum OrderShippingStatus {
  PENDING = 'pending', // package still pending to be shipped
  DELIVERED = 'delivered', // package has been delivered to client
  SHIPPED = 'shipped',

  // ADMITTED = 'admitted', // package admited by the shipping service
  // INTRANSIT = 'inTransit', // package is in transit
  // CANCELED = 'canceled', // shipping has been canceled (before being in transit?)
  // RETURNED = 'returned', // package has been returned by final user (or not received?)
}

export class Transaction {  // NEW 2
  createdAt: Date;
  orderId: string; // reference to order document
  paymentStatus: OrderPaymentStatus;
  paymentType: PaymentType;
  paymentService?: PaymentServiceType; // only if credit card, service used
  data: any;   // service dependant
}

export enum PrintFileSubStatus {
  NONE = 'none',
  UPLOADING = 'uploading',
  CONVERTING = 'converting',
  COUNTING = 'counting',
  THUMBNAIL = 'thumbnail',
}

export enum DiscountType { WALLET = 'wallet', COUPON = 'coupon' }

//hay duplicacion de modelo para poder ustilizar el pipe
export enum PiggyTransactionType {
  WALLET = 'wallet',
  BANK = 'bank',
  FEE = 'fee',
  ROLLBACK = 'rollback',
  EXPIRATION = 'expiration'
}

export enum PiggyTransactionStatus {
  PENDING = 'pending',  // bank, fee
  COMPLETE = 'complete', // wallet, bank, fee (like withdrawn), rollback
  REJECTED = 'rejected', // bank
  EXPIRED = 'expired',  // fee
  ERROR = 'error',    // not even used...
}

export enum PiggyTransactionOption {
  SHARE = 'share',
  REFERRER = 'referrer',
  COUPON = 'coupon',
  CREATOR = 'creator',
  ROYALTY = 'royalty',
}


/*

Client Procedure:

1.- Sign in (anonymous or Google Facebook)
  - Update user record (collection: users)
  - Register token when available (collection: users.tokens). (after purchase?)
2.- Add to cart:
  - Cart is to be stored in Firebase (collection: carts) on every change
  - Files need to be uploaded to storage, and result stored in DB temporarily (collection: temporaryUpload)
  - Files removed from cart? -> if possible, remove documents from DB (collection: temporaryUpload)
  - Adding a Binding to a printingGroup, adds the product Binding to the item list. (PrintingGroup has got a parent/child reference)
  - Binding product is always a child item. Child items can't be removed aloone. Only parents.
  2.1.- After uploading to temporary storage:
    - Add document to collection: temporaryUpload (PrintFile). Only needs: createdAt, updatedAt, originalFile, status = NEW.
    - Listen to document and wait for changes on "status". If FINISHED, "pages" and "originalType" will be available.
    - If originalType = OTHER, file type is unsupported. Don't allow order to be placed until this item is removed
3.- Place Order:
  - Transactionally update doc: settings.nextOrderNumber to get order number
  - Create order with all required fields (items[] and price from cart)



Server Procedure:

* onCreateTemporaryUploads:
- if (!pdf), call ilovepdf API
- get file and upload to storage
- count pages
- update document with new data (including updatedAt)

* onCreateOrder:
- Remove cart document (from cartId)

* onWriteOrder:
(check on status change to send notifications)

*/

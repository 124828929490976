import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { serverTimestamp } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Setting } from '../models/setting.model';
import { ShippingSettings } from '../models/shipping-settings.model';
import { objectToArr } from './utils/utils';

export type IndexCollection = 'general' | 'admin' | 'server' | 'banners';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  private collection: AngularFirestoreCollection<any>;
  public generalId = 'general'; //nombre del documento 'general';

  constructor(
    private db: AngularFirestore,
  ) {
    this.collection = this.db.collection<any>('settings');
  }

  get(id: string): Observable<any> {
    return this.collection.doc<any>(id).valueChanges();
  }

  /**
   * gets one of the specified collections
   */
  getSettings(index: IndexCollection): Observable<any> {
    return this.collection.doc(index)
      .valueChanges().pipe();
  }

  upsert(uData): Promise<any> {
    const { id, ...data } = uData;// aquí se tiene la info...
    //console.log("Actualizando en: ", id);
    data.updatedAt = serverTimestamp();
    return (id)
      ? this.collection.doc(id).update(data)
      : this.collection.add(data);
  }

  getBanner(): Observable<string> {
    return this.get(this.generalId).pipe(map(general => general.homeBanner));
  }

  getGeneral(): Observable<Setting.General> {
    return this.get(this.generalId);
  }

  getBanners(): Observable<Setting.AppBanners> {
    return this.get('banners');
  }

  getShippingSettings() {
    return this.db.firestore.collection('settings').doc('shippingSettings').get()
      .then(snap => {
        const {
          freeWeightLimit,
          weightPrices,
          priceRanges,
          zoneWeightPrices,
          zoneFreeCases,
          zoneShippingInfo,
          shippingTypeRestrictions,
          extra_economic,
          extra_24_48,
          extra_24,
          extra_urgent,
          available,
          deliveryDaysRanges
        } = snap.data();
        const shippingSettings = {
          extra_economic, extra_24_48, extra_24, extra_urgent, available,
          freeWeightLimit, shippingTypeRestrictions,
          weightPrices: { ...weightPrices, range: objectToArr(weightPrices.range) },
          priceRanges,
          deliveryDaysRanges,
          zoneWeightPrices: objectToArr(zoneWeightPrices).map(zwp => (zwp.zone = objectToArr(zwp.zone), zwp.range = objectToArr(zwp.range), zwp)),
          zoneFreeCases: objectToArr(zoneFreeCases).map(zfc => (zfc.zone = objectToArr(zfc.zone), zfc)),
          zoneShippingInfo: objectToArr(zoneShippingInfo).map(zsi => (zsi.zone = objectToArr(zsi.zone), zsi))
        };
        return shippingSettings as any as ShippingSettings;
      });
  }


}



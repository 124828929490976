import { ModalController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PrintProductStateService } from "../../shared/print-product-state.service";
import {
  TooltipInfoColorComponent
} from "../tooltip-info-components/tooltip-info-color/tooltip-info-color.component";
import { Finishing, PaperWeight, SettingCategoryText, Settings } from "../../models/models";
import {
  GroupedBindingSelectorComponent
} from "../../components/grouped-binding-selector/grouped-binding-selector.component";
import { CookieService } from "ngx-cookie-service";
import {
  IncompatibleLaminationAlertComponent
} from "../../components/incompatible-lamination-alert/incompatible-lamination-alert.component";
import { ProductService } from "../../shared/product.service";
import { AppService } from "../../shared/app.service";

@Component({
  selector: 'app-options-button',
  templateUrl: './options-button.component.html',
  styleUrls: ['./options-button.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,

})
export class OptionsButtonComponent implements OnInit {
  @Input() settings;
  @Input() color = "light";
  @Input() settingOption;
  @Input() hasImage;
  @Input() selectorType = 'buttons';
  @Output() optionSelect = new EventEmitter<any>();
  @Output() settingSelectedEvent = new EventEmitter()
  @ViewChild('container', { read: ElementRef, static: false }) containerRef: ElementRef
  // @HostListener('document:mousemove', ['$event']) 
  // onMouseMove(e) {
  //   // if(this.infoTip && (Math.abs(this.infoCoord.screenX -  e.screenX) > 22 || Math.abs(this.infoCoord.screenY -  e.screenY) > 25)){
  //   console.log("Sale A", e);
  //   if(this.infoTip && (Math.abs(this.infoCoord.screenX -  e.screenX) > 20 || Math.abs(this.infoCoord.screenY -  e.screenY) > 20)){
  //     console.log("Sale", e);
  //     this.infoTip.dismiss();
  //   }
  // }
  //setting;
  infoTip = null;
  infoCoord = {
    screenX: 0,
    screenY: 0
  }
  infoTimeout;
  isOpened: boolean = null;
  isMobile: boolean = null;

  printProduct;
  toastShown = false;

  constructor(
    public popoverController: PopoverController,
    private modalController: ModalController,
    private printProductStateService: PrintProductStateService,
    private toastController: ToastController,
    private platform: Platform,
    private cookieService: CookieService,
    private productService: ProductService,
    private toastCtrl: ToastController,
    protected appService: AppService,
  ) {
  }

  ngOnInit() {
    //this.setting = this.settings[this.settingOption];
    this.checkDevice();
    this.printProductStateService.printProduct$.subscribe(printProduct => {
      this.printProduct = printProduct;
    })
    if (this.settings[this.settingOption].categorized) {
      this.setCategorizedOptions(this.settings[this.settingOption].options);
    }
  }

  ngAfterViewInit() {
    this.fixContainerHeight();
  }

  private checkDevice() {
    let platforms = this.platform.platforms();
    this.isMobile = !!(platforms.find(type => type == "mobile"));
  }

  async selectOption(i: number, event: MouseEvent | null = null): Promise<void> {
    const selectedOption = this.settings[this.settingOption].options[i];
    const isSelected = this.settings[this.settingOption].selected === i + 1;

    if (isSelected) return;

    if (selectedOption.value === 'binding' && !this.cookieService.get('bindingSelected')) {
      await this.handleBindingSelection(event, i);
    } else if (this.settingOption === Settings.FINISHING && selectedOption.value === 'laminated') {
      await this.handleLaminationSelection(i)
    } else {
      this.updateSelectedOption(i);
    }
  }

  private async handleBindingSelection(event: MouseEvent | null, i: number): Promise<void> {
    const button = event?.currentTarget as HTMLElement;
    const rect = button.getBoundingClientRect();
    const popover = await this.popoverController.create({
      component: GroupedBindingSelectorComponent,
      cssClass: this.isMobile ? 'groupedBindingSelectorPopoverMobile' : 'groupedBindingSelectorPopover',
      event: { target: { getBoundingClientRect: () => rect } } as any,
      alignment: 'center',
      side: this.isMobile ? 'left' : 'right', // Coloca el popover en el lado derecho
      componentProps: { settings: this.settings },
    });

    popover.onDidDismiss().then((result) => {
      if (result.data) {
        this.cookieService.set('bindingSelected', '1');
        this.updateGroupedSetting(result.data.grouped);
        this.updateSelectedOption(i);
      }
    });

    await popover.present();
  }

  private async handleLaminationSelection(i) {
    const printGroupFiles = this.printProduct.product.printingGroup.files;
    const selectedPaperWeights = new Set(
      printGroupFiles.map(file => file.coverType.paperWeight)
        .concat(this.settings[Settings.PAPER_WEIGHT].options[this.settings[Settings.PAPER_WEIGHT].selected - 1].value)
    );

    const incompatibleFinishings = Array.from(selectedPaperWeights).flatMap(paperWeight =>
      this.settings.paperWeight.options.find(option => option.value === paperWeight)?.unavailableFor?.find(unavailable => unavailable.id === 'finishing')?.options || []
    );

    if (incompatibleFinishings.includes(i + 1)) {
      const modal = await this.modalController.create({
        component: IncompatibleLaminationAlertComponent,
        cssClass: 'auto-height',
      });

      modal.onDidDismiss().then(async (result) => {
        if (result.data) {
          this.productService.restartPaperWeight(this.printProduct, PaperWeight.W80, this.settings, this.optionSelect);
          this.updateSelectedOption(i);
          const toast = await this.toastController.create({
            icon: 'checkmark-circle-outline',
            message: 'Gramaje del papel actualizado con éxito',
            color: "success",
            position: 'bottom',
            animated: true,
            cssClass: "fitWidthToast successToast",
            duration: 3000,
          });
          await toast.present();
          setTimeout(() => {
            const triggerElement = document.getElementById('tooltip-icon-lamination-weight-setting');
            if (triggerElement) {
              triggerElement.click(); // Simula el clic en el trigger asociado
            }
          }, 500)
        }
      });
      await modal.present();
    } else {
      this.updateSelectedOption(i);
    }
  }

  private updateGroupedSetting(grouped: string): void {
    const selectedIndex = this.settings[Settings.GROUPED].options.findIndex(option => option.value === grouped) + 1;
    this.settings[Settings.GROUPED].selected = selectedIndex;
    this.optionSelect.emit({
      setting: Settings.GROUPED,
      option: selectedIndex,
      prev: this.settings[Settings.GROUPED].selected
    });
  }

  private updateSelectedOption(i: number): void {
    const prev = this.settings[this.settingOption].selected;
    this.settings[this.settingOption].selected = i + 1;
    this.optionSelect.emit({
      setting: this.settingOption,
      option: this.settings[this.settingOption].selected,
      prev: prev
    });
  }

  selectOptionCheck(event) {
    const checked = event.detail.checked;
    this.selectOption(checked ? 0 : 1);
  }

  isDisabled(i) {
    let isAvailable = this.checkAvailability(i)
    if (!isAvailable && this.settings[this.settingOption].selected === i + 1) this.selectAnother();
    return !isAvailable
  }

  isHidden(i) {
    let hideSubtitleFor = this.settings[this.settingOption].options[i].hideSubtitleFor || [];
    return -1 !== hideSubtitleFor.findIndex(hidden => {
      return hidden.options.includes(this.settings[hidden.id].selected)
    });
  }

  selectAnother() {
    //buscar el primer disponible para seleccionar y sino -1
    const available = this.settings[this.settingOption].options.findIndex((ele, index) => this.checkAvailability(index))
    let setting = this.settings[this.settingOption];
    //TODO: Verificar porque se ejecuta dos veces
    setTimeout(() => {
      this.optionSelect.emit({
        setting: this.settingOption,
        option: available + 1,
        prev: this.settings[this.settingOption].selected
      });
      setting.selected = available + 1;
    }, 10);

  }

  checkAvailability(i) {
    const option = this.settings[this.settingOption].options[i];
    const isSelected = this.settings[this.settingOption].selected === i + 1;

    if (option.title === 'Grapado' && (this.isSomeCoverNotDefault() || this.isStapplingExceeded())) {
      if (isSelected && !this.toastShown) {
        let message = '';

        if (this.isStapplingExceeded()) {
          message = 'Se ha desactivado el grapado ya que la impresión de algún documento excede los 50 folios';
        } else if (this.isSomeCoverNotDefault()) {
          message = 'Se ha desactivado el grapado ya que no está disponible para configuraciones de portada distintas a la predeterminada';
        }

        if (message) {
          this.toastShown = true;
          this.toastController.create({
            message: message,
            color: 'danger',
            position: 'bottom',
            cssClass: 'fitWidthToast',
            duration: 5000
          }).then(toast => {
            toast.present();

            setTimeout(() => {
              this.toastShown = false;
            }, 3000);
          });
        }
      }
      return false;
    }
    const unavailableFor = option.unavailableFor || [];
    const unavailableForGrouped = option.unavailableForGrouped || [];

    const isUnavailable = unavailableFor.some(unavailable =>
      unavailable.options.includes(this.settings[unavailable.id].selected)
    );

    const isUnavailableGrouped = unavailableForGrouped.some(group =>
      group.properties.every(unavailable =>
        unavailable.options.includes(this.settings[unavailable.id].selected)
      )
    );

    return !isUnavailable && !isUnavailableGrouped;
  }

  async clickInfo(event: MouseEvent, type = "mouseover") {
    const popover = await this.popoverController.create({
      component: TooltipInfoColorComponent,
    });

    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log(`Popover dismissed with role: ${role}`);

  }

  public elementScrollTop(id: string): void {
    const element: HTMLElement = document.getElementById(id);
    // element.scrollTo(0,0);
    element.scrollIntoView();

  }

  public buttonSubtitle(option, i) {
    return option?.subTitle ?? ''
    //los mensajes que aparecian como subtitulos ahora aparecen como tooltips
    const unavailableForIndex = option.unavailableFor
      ? option.unavailableFor.findIndex(unavailable => {
        return unavailable.options.includes(this.settings[unavailable.id].selected)
      })
      : -1;
    let altSubtitle = null;

    if (this.settings[this.settingOption].options[i].title === 'Grapado') {
      if (this.isStapplingExceeded()) {
        altSubtitle = 'Límite excedido';
      }

      if (this.isSomeCoverNotDefault()) {
        altSubtitle = 'Sólo tipo de portada por defecto';
      }
    }


    if (unavailableForIndex > -1) {
      altSubtitle = option.unavailableFor[unavailableForIndex].subTitle ?? null;
      // console.log("Availability", unavailableForIndex);
    }
    return altSubtitle ?? option?.subTitle

  }

  private isStapplingExceeded() {
    return this.printProduct.product.printingGroup.files.some(fil => fil.printSheets >= 50);
  }

  private isSomeCoverColor() {
    return this.printProduct.product.printingGroup.files.some(fil => fil.coverColor);
  }

  private isSomeCoverNotDefault() {
    return this.printProduct.product.printingGroup.files.some(fil => !fil.coverType?.default
      || fil?.coverType?.paperWeight !== this.settings[Settings.PAPER_WEIGHT].options[this.settings[Settings.PAPER_WEIGHT].selected - 1].value);
  }

  private isSomeCoverLaminated() {
    return this.printProduct.product.printingGroup.files.some(fil => fil.coverLaminated);
  }

  private fixContainerHeight() {
    // setTimeout(() => {
    //   if (this.containerRef) {
    //     const height = this.containerRef.nativeElement.offsetHeight;
    //     this.containerRef.nativeElement.style.minHeight = height + 'px'
    //   }
    // }, 500)
  }

  protected readonly Finishing = Finishing;

  protected readonly Settings = Settings;

  shouldShowCategory(options: any, i: number) {

    return i === 0 || options[i - 1] && options[i - 1].settingCategory !== options[i].settingCategory;
  }

  getSettingCategoryText(option: any) {
    return SettingCategoryText[option.settingCategory];
  }

  getTooltip(option, i) {
    let tooltip = null;
    const unavailableForIndex = option.unavailableFor
      ? option.unavailableFor.findIndex(unavailable => {
        return unavailable.options.includes(this.settings[unavailable.id].selected)
      })
      : -1;
    if (unavailableForIndex > -1) {
      tooltip = option.unavailableFor[unavailableForIndex].disabledTooltip ?? null;
      if (!!tooltip) {
        return tooltip;
      }
    }

    const unavailableForGroupedIndex = option.unavailableForGrouped
      ? option.unavailableForGrouped.findIndex(group => {
        return group.properties.every(unavailable => {
          return unavailable.options.includes(this.settings[unavailable.id].selected);
        });
      })
      : -1;

    if (unavailableForGroupedIndex > -1) {
      tooltip = option.unavailableForGrouped[unavailableForGroupedIndex].disabledTooltip ?? tooltip;
    }
    if (this.settings[this.settingOption].options[i].title === 'Grapado') {
      if (this.isStapplingExceeded()) {
        tooltip = 'Límite de folios excedido';
      }

      if (this.isSomeCoverNotDefault()) {
        tooltip = 'Sólo tipo de portada por defecto';
      }
    }

    return tooltip;
  }

  isBindingSelected(option: any): boolean {
    const finishing = this.printProduct.product.printingGroup.printSettings.finishing;
    const selectedOption = this.settings[this.settingOption].selected;
    const bindingIndex = this.appService.getSelectedIndexForOption(Settings.FINISHING, { value: 'binding' });
    const bindingWithCoverLaminatedIndex = this.appService.getSelectedIndexForOption(Settings.FINISHING, {
      value: 'binding',
      extraData: { isBindingWithCoverLaminated: true }
    });
    return finishing === 'binding' &&
      ((selectedOption === bindingIndex && !option?.extraData?.isBindingWithCoverLaminated) ||
        (selectedOption === bindingWithCoverLaminatedIndex && option?.extraData?.isBindingWithCoverLaminated));
  }

  setCategorizedOptions(options: any) {
    let index = 0
    this.settings[this.settingOption].categorizedOptions = options.reduce((acc, option) => {
      const lastCategory = acc[acc.length - 1];
      const optionWithIndex = { ...option, index: index++ };
      if (!lastCategory || lastCategory.settingCategory !== option.settingCategory) {
        acc.push({ settingCategory: option.settingCategory, options: [optionWithIndex] });
      } else {
        lastCategory.options.push(optionWithIndex);
      }
      return acc;
    }, []);
  }

  isSelected(i: number) {
    return this.settings[this.settingOption].selected === i + 1;
  }
}

import { OrderService } from 'src/app/shared/order.service';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { serverTimestamp, Timestamp } from '@angular/fire/firestore';

import { Order } from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class OrderDraftService {
  private collection: AngularFirestoreCollection<Order>;

  private addedDraft = new BehaviorSubject<any>(null);
  public addedDraft$: Observable<any> = this.addedDraft.asObservable();

  constructor(
    private db: AngularFirestore,
    private orderService: OrderService
  ) { 
    this.collection = this.db.collection<Order>('draftOrders');     
  }

  addDraft(_order): Promise<any>{
    const {id, ...order} = _order;
    order.createdAt = serverTimestamp();
    return order.userId ?
       this.db.collection('users').doc(order.userId)
        .collection('draftOrders').add(order).then(() => this.addedDraft.next(null))
      :Promise.reject()
  }

  /*getOrders(id, limit:number=20): Observable<any[]>{
    return this.db.collection('users').doc(id).collection<any>('draftOrders', ref=> ref.orderBy('createdAt', 'desc').limit(limit))
    .snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data =  this.normalizeResponse(a.payload.doc.data());
        const id = a.payload.doc.id;
        return {id,  ...data };
      }))
    )    
  }*/

  getOrders(userId, pageSize?, startAfterDoc = null) {

    return this.db.collection('users').doc(userId).collection<any>('draftOrders', ref => {
      let queryRef = ref.orderBy('createdAt', 'desc').limit(pageSize);
      if (startAfterDoc) {
        queryRef = queryRef.startAfter(startAfterDoc);
      }
      return queryRef;
    })
    .snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data =  this.normalizeResponse(a.payload.doc.data());
        const id = a.payload.doc.id;
        return {id,  ...data };
      }))
    )

    /*let query = this.db.collection('draftOrders', ref => {
      let queryRef = ref.where('userId', '==', userId).orderBy('createdAt', 'desc').limit(pageSize);
      if (startAfterDoc) {
        queryRef = queryRef.startAfter(startAfterDoc);
      }
      return queryRef;
    })

    return query.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = this.normalizeResponse(a.payload.doc.data());
        const id = a.payload.doc.id;
        return {id, ...data};
      })
      )
    )*/
  }

  delete(userId: string, id){
    return this.db.collection('users')
      .doc(userId)
      .collection<any>('draftOrders')
      .doc(id).delete();
  }

  normalizeResponse(data){
    data.createdAt = this.ifTimestamp2Date(data.createdAt)
    data.cart.createdAt = this.ifTimestamp2Date(data.cart.createdAt)
    data.cart.updatedAt = this.ifTimestamp2Date(data.cart.updatedAt)
    data = this.orderService.back2Present(data);
    return data;
  }

  ifTimestamp2Date = property =>  property instanceof Timestamp ?  property.toDate() : property;


}

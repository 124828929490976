import { Component, EventEmitter, Input, OnInit, Output, Renderer2, signal } from '@angular/core';
import {
  ProductCustomizableElements,
} from "../../../models/product.model";
import { AppService } from "../../../shared/app.service";
import { ProductService } from "../../../shared/product.service";

@Component({
  selector: 'app-product-custom-elements',
  templateUrl: './product-custom-elements.component.html',
  styleUrls: ['./product-custom-elements.component.scss']
})
export class ProductCustomElementsComponent implements OnInit {
  @Output() changes = new EventEmitter();
  @Input() product;
  elementType: typeof ProductCustomizableElements = ProductCustomizableElements;
  options = [];
  optionsSelected: any = {};
  availablePaperFormats = [];
  private paperSizes: any[];
  private paperColors: any[];
  private paperWeights: any[];
  private paperPatterns: any[];
  printSettings;
  baseProductPrice = 0;
  segmentBinding = 'rings';
  segmentPaperType = 'white';
  selectedColor: any;
  currentDiameter = 0;

  paperColorData = {
    'Blanco': { color: '#ffffff' },
    'Rayas': { src: 'assets/images/product-preview/notebook/old/notebook-background-lines-old.png' },
    'Puntos': { src: 'assets/images/product-preview/notebook/old/notebook-background-points-old.png' },
    'Amarillo': { color: '#f7ee6f' },
    'Azul celeste': { color: '#B8E8F2' },
    'Crema': { color: '#F2D6AA' },
    'Fucsia': { color: '#FE4196' },
    'Lila': { color: '#9966CC' },
    'Naranja': { color: '#FF934A' },
    'Rosa': { color: '#FFB9D6' },
    'Verde': { color: '#81E2B2' },
    'Hueso': { color: '#E3DAC9' },

    // Los siguientes no están disponibles
    'Mint': { color: '#7dea9a' },
    'Azul pastel': { color: '#9dd7ef' },
    'Amarillo lima': { color: '#e6f16d' },
    'Salmón': { color: '#f79d82' },

  }

  constructor(public appService: AppService, private productService: ProductService) {
    //console.log('Product custom yeah');
  }

  ngOnInit() {
    this.product.customElementsSelected = null;
    this.product.customCombination = '';
    this.baseProductPrice = this.product.price.amt;
    this.printSettings = this.appService.getPrintSettings();
    this.initializeCustomOptions()

  }

  // ionViewDidLeave() {
  //   console.log('ionViewDidLeaveeeeeee');
  //   this.optionsSelected = null;
  // }

  public initializeCustomOptions() {
    this.options = [];
    this.product.customElements.forEach(ce => {
      switch (ce.id) {
        case this.elementType.PAPER_FORMAT:
          this.availablePaperFormats = ce.values.filter(v => v.available);
          this.paperWeights = [];
          this.paperColors = [];
          this.paperPatterns = [];
          this.paperSizes = [];

          this.availablePaperFormats.forEach(val => {
            if (!this.paperWeights.some(item => item.value === val.paperWeight)) {
              this.paperWeights.push({ name: `${val.paperWeight} g`, value: val.paperWeight });
            }
            if (!this.paperColors.some(item => item.value === val.paperColor)) {
              this.paperColors.push({ name: val.paperColor, value: val.paperColor });
            }
            if (!this.paperPatterns.some(item => item.value === val.paperPattern)) {
              this.paperPatterns.push({ name: val.paperPattern, value: val.paperPattern });
            }
            if (!this.paperSizes.some(item => item.value === val.paperSize)) {
              this.paperSizes.push({ name: val.paperSize, value: val.paperSize });
            }

          })
          this.paperColors = this.getPaperColorValues([...this.paperColors]);

          this.options.push({
            id: 'paperWeight',
            name: 'Grosor del papel',
            type: 'paper',
            values: [...this.paperWeights].sort((a, b) => +a.value - +b.value)
          });
          this.options.push({
            id: 'paperColor',
            name: 'Color del papel',
            type: 'paper',
            pending: true,
            values: this.paperColors.filter(op => !!op.data.color)
          });
          this.options.push({
            id: 'paperPattern',
            name: 'Motivo del papel',
            type: 'paper',
            pending: true,
            values: this.paperPatterns.filter(op => !!op.value)
          });
          this.options.push({
            id: 'paperSize',
            name: 'Tamaño del papel',
            type: 'paper',
            pending: true,
            values: [...this.paperSizes]
          });
          this.optionsSelected.paperWeight = null;
          this.optionsSelected.paperColor = null;
          this.optionsSelected.paperPattern = null;
          this.optionsSelected.paperSize = null;
          break;
        case this.elementType.SHEET_NUMBER:
          this.optionsSelected[ce.id] = null;
          this.options.push({
            id: ce.id,
            name: ce.name,
            type: 'paper',
            values: [...ce.values.filter(v => v.available)]
          });
          break;
        case this.elementType.RINGS:
          this.optionsSelected[ce.id] = null;
          this.options.push({
            id: ce.id,
            name: ce.name,
            type: 'binding',
            imgPath: 'assets/images/binding/rings/',
            values: [...ce.values.filter(v => v.available)]
          });
          break;
        case this.elementType.COVER_BACK:
        case this.elementType.COVER_FRONT:
          this.optionsSelected[ce.id] = null;
          this.options.push({
            id: ce.id,
            name: ce.name,
            type: 'binding',
            values: [...ce.values.filter(v => v.available)]
          });
          break;
      }
    })
    this.setDefaultOptions();
    this.groupOptionsByCategory()
  }

  groupOptionsByCategory() {
    this.options.forEach(option => {
      if (option.values && option.values.length > 0) {
        option.groupedOptions = {
          noCategory: [],
          groupedByCategory: {}
        };

        option.values.forEach(value => {
          if (value.category) {
            // Si el valor tiene categoría, lo añadimos a groupedByCategory
            if (!option.groupedOptions.groupedByCategory[value.category]) {
              option.groupedOptions.groupedByCategory[value.category] = [];
            }
            option.groupedOptions.groupedByCategory[value.category].push(value);
          } else {
            // Si no tiene categoría, lo añadimos a noCategory
            option.groupedOptions.noCategory.push(value);
          }
        });
      }
    });
  }

  originalOrder = () => 0

  getCategoryText(category: string) {
    let result = ''
    switch (category) {
      case 'exclusive':
        result = 'Exclusivas Copyfly:'
        break;
      case 'glitter':
        result = 'Anillas con PURPURINA:'
        break;
      case 'fluorescent':
        result = 'Colores flúor:'
        break;
      case 'standard':
        result = 'Colores estándar:'
        break;
      case 'pastel':
        result = 'Colores pastel:'
        break;
      case 'nature':
        result = 'Colores nature:'
        break;
      default:
        result = ''
        break;
    }
    return result
  }

  getGlobalIndex(i: number, option, iCat: number) {
    let offset = option.groupedOptions.noCategory.length;
    Object.entries(option.groupedOptions.groupedByCategory).forEach(([key, value]: [string, Array<any>[]], index) => {
      if (iCat > index) {
        offset += value.length
      }
    });
    return i + offset
  }


  checkPaperAvailability(propertyChanged, val) {

    if (propertyChanged === 'paperWeight') {
      this.options.find(op => op.id === 'paperColor').values = [...this.paperColors].filter(pc => this.availablePaperFormats.some(av =>
        av.paperWeight === val.value &&
        av.paperColor === pc.value
      ));
      this.options.find(op => op.id === 'paperPattern').values = [...this.paperPatterns].filter(pp => this.availablePaperFormats.some(av =>
        av.paperWeight === val.value &&
        av.paperPattern === pp.value
      ));
      this.options.find(op => op.id === 'paperSize').values = [...this.paperSizes].filter(ps => this.availablePaperFormats.some(av =>
        av.paperWeight === val.value &&
        av.paperSize === ps.value
      ));
    }

    /*if (propertyChanged === 'paperSize') {
      this.options.find(op => op.id === 'paperColor').values = [...this.paperColors].filter(pc => this.availablePaperFormats.some(av => 
        av.paperSize === val.value && 
        av.paperColor === pc.value
      ));
      this.options.find(op => op.id === 'paperPattern').values = [...this.paperPatterns].filter(pp => this.availablePaperFormats.some(av => 
        av.paperSize === val.value &&
        av.paperPattern === pp.value
      ));
      this.options.find(op => op.id === 'paperWeight').values = [...this.paperWeights].filter(pw => this.availablePaperFormats.some(av => 
        av.paperSize === val.value &&
        (this.optionsSelected.paperColor ? av.paperColor === this.optionsSelected.paperColor.value : true) && 
        av.paperWeight === pw.value)).sort((a, b) => +a.value - +b.value);
      
      // Si se cambia el tamaño del papel, se reestablecen las opciones del motivo de papel
      //this.options.find(op => op.id === 'paperColor').values = [...this.paperColors].filter(pt => pt.name === 'Blanco' || pt.name === 'Rayas' || pt.name === 'Puntos');

      
      //this.options.find(op => op.id === 'paperMotif').values = [...this.paperTypes].filter(pt => this.availablePaperFormats.some(av => av.pageSize === val.value
      //  && av.paperType === pt.value && (av.paperType === 'Blanco' || av.paperType === 'Puntos' || av.paperType === 'Rayas')));
    }*/
    if (propertyChanged === 'paperColor') {
      this.selectedColor = val.name;

      this.options.find(op => op.id === 'paperPattern').values = [...this.paperPatterns].filter(pp => this.availablePaperFormats.some(av =>
        av.paperWeight === this.optionsSelected.paperWeight.value &&
        av.paperColor === val.value && av.paperPattern === pp.value));

      // Se actualizan las opciones disponibles de grosor que se muestran en función del color del papel seleccionado
      this.options.find(op => op.id === 'paperSize').values = [...this.paperSizes].filter(ps => this.availablePaperFormats.some(av =>
        av.paperWeight === this.optionsSelected.paperWeight.value &&
        av.paperColor === val.value && av.paperSize === ps.value));
    }

    if (propertyChanged === 'paperPattern') {
      this.options.find(op => op.id === 'paperSize').values = [...this.paperSizes].filter(ps => this.availablePaperFormats.some(av =>
        av.paperWeight === this.optionsSelected.paperWeight.value &&
        av.paperColor === this.optionsSelected.paperColor.value &&
        av.paperPattern === val.value &&
        av.paperSize === ps.value
      ));
    }

    if (!this.options.find(op => op.id === 'paperColor').values.some(val => val.value === this.optionsSelected.paperColor?.value)) {
      this.optionsSelected.paperColor = null;
    }
    if (!this.options.find(op => op.id === 'paperPattern').values.some(val => val.value === this.optionsSelected.paperPattern?.value)) {
      this.optionsSelected.paperPattern = null;
    }
    if (!this.options.find(op => op.id === 'paperSize').values.some(val => val.value === this.optionsSelected.paperSize?.value)) {
      this.optionsSelected.paperSize = null;
    }
  }

  isDisabled(option, value) {
    if (!['pageSize', 'paperColor', 'paperWeight'].includes(option.id)) {
      return false;
    }
    //TODO esto se usara para mostrar en gris los elementos sin stock cuando se controle el stock
    return false
  }

  clickInput(id: string, i) {
    if (this.options[i]?.pending) {
      return
    }
    document.getElementById(id).click()
  }

  onChange(option: any, i: number, value: any) {
    this.optionsSelected[option.id] = value;
    if (option.id === 'paperWeight') {
      // Esto es para poner en pending todas las opciones después del tamaño del papel
      /*this.options.forEach(op => {
        if (op.id !== 'pageSize')
          op.pending = true;
      })*/
      for (let index = i + 1; index < this.options.length; index++) {
        this.options[index].pending = true;
      }

      // Para deseleccionar los inputs de la opción de motivo de papel cuando se cambia el pageSize
      //document.querySelectorAll<HTMLInputElement>('input[id^="Motivo del papel"]').forEach((x) => x.checked = false /*this.render.setProperty(x, 'checked', false)*/);
      //if (this.optionsSelected.paperType) // Se borra el motivo de papel si el papel no es blanco
      this.optionsSelected.paperColor = null;
      this.optionsSelected.paperPattern = null;
      this.optionsSelected.paperSize = null;
      this.optionsSelected['sheetNumber'] = null;
    }

    if (option.id === 'paperColor') {
      for (let index = i + 1; index < this.options.length; index++) {
        this.options[index].pending = true;
      }
      this.optionsSelected.paperPattern = null;
      this.optionsSelected.paperSize = null;
      this.optionsSelected['sheetNumber'] = null;
    }

    if (['sheetNumber', 'paperWeight', 'paperSize'].includes(option.id)) {
      const { sheetNumber, paperWeight, paperSize } = this.optionsSelected;
      if (sheetNumber && paperWeight && paperSize) {
        const binding = this.productService.findBindingRing(sheetNumber.value, paperSize.value, paperWeight.value)
        this.currentDiameter = binding.diameter
      }
    }

    if (this.options[i + 1]) {
      this.options[i + 1].pending = false;
    }

    this.productService.getCustomProductPrice(this.baseProductPrice, this.optionsSelected, this.product).then(r => {
      this.product.unitPrice.amt = r
    });

    this.productService.getCustomProductWeight(this.optionsSelected).then(r => {
      this.product.weight = r
    });
    this.checkPaperAvailability(option.id, value);
    this.changes.emit(this.optionsSelected);
    this.product.customElementsSelected = Object.values(this.optionsSelected).every(value => value !== null) ? this.optionsSelected : null;
    if (this.product.customElementsSelected?.[ProductCustomizableElements.RINGS])
      this.product.customElementsSelected[ProductCustomizableElements.RINGS].diameter = this.currentDiameter

    this.product.customCombination = this.getCustomCombinationString()
  }

  getColor(color) {
    if (color.id === 'transparent') {
      return 'repeating-linear-gradient(-45deg, white, white 4px, rgba(0, 0, 0, 0.1) 4px, rgba(0, 0, 0, 0.1) 9px);';
    } else {
      return color.color;
    }
  }

  private getPaperColorValues(elements) {
    return elements.map(el => {
      return { ...el, data: { ...this.paperColorData[el.name] } }
    })
  }

  private setDefaultOptions() {
    let defaultValue = null;
    Object.entries(this.optionsSelected).forEach(([key, value]: [string, any]) => {
      switch (key) {
        case ProductCustomizableElements.RINGS :
          defaultValue = this.options.find(op => op.id === key).values.find(val => val.id === 'transparent') ?? null;
          this.optionsSelected[key] = defaultValue
          break;
        case ProductCustomizableElements.COVER_FRONT :
          defaultValue = this.options.find(op => op.id === key).values.find(val => val.id === 'transparent') ?? null;
          this.optionsSelected[key] = defaultValue
          break;
        case ProductCustomizableElements.COVER_BACK :
          defaultValue = this.options.find(op => op.id === key).values.find(val => val.id === 'black') ?? null;
          this.optionsSelected[key] = defaultValue
          break;
        case 'paperColor' :
          defaultValue = this.options.find(op => op.id === key).values.find(val => val.name === 'blanco') ?? null;
          this.optionsSelected[key] = defaultValue;
          break;
        case 'paperPattern':
          defaultValue = this.options.find(op => op.id === key).values.find(val => val.name === 'Liso') ?? null;
          this.optionsSelected[key] = defaultValue
          break;
      }
    })
  }

  onSegmentChange(event, segmentType) {
    if (segmentType === 'binding') {
      this.segmentBinding = event.detail.value
    } else {
      this.segmentPaperType = event.detail.value
    }
  }

  getOptionById(id) {
    return this.options.find(op => op.id === id)
  }

  optionsHaveBinding() {
    return this.options.some(op => op.type === 'binding');
  }

  getPaperSegmentValues() {
    return this.segmentPaperType === 'white' ? this.getOptionById('paperColor').values.filter(op => !op.data.color) : this.getOptionById('paperColor').values.filter(op => !!op.data.color)
  }

  private getCustomCombinationString() {
    const elements = this.product.customElementsSelected
    if (!elements) {
      return ''
    }

    //Properties positions and order in final string
    const order = [
      elements.paperSize.name ?? '?',
      elements.paperWeight.name ?? '?',
      elements.paperColor.name ?? '?',
      elements.paperPattern.name ?? '?',
      elements.sheetNumber.name ?? '?',
      elements.rings.name ?? '?',
      elements.coverFront.name ?? '?',
      elements.coverBack.name ?? '?'
    ];

    const concatenatedString = order.join(", ");
    return `${this.product.sku} / ${concatenatedString}`
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { OutputMessage, ReceptorMessage } from "src/app/models/models";
import { Product } from "src/app/models/product.model";
import { ProductBlService } from "src/app/shared/services/productBl.service";
import { ShopBlService } from "src/app/shared/services/shopBl.service";
import { ProductDbService } from "../../../shared/database/product-db.service";
// import 'swiper/scss';
// import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar, SwiperOptions } from 'swiper';
import _Product = Product._Product;
import cheapestVariant = Product.cheapestVariant;
import highestDiscount = Product.highestDiscount;
import { ProductList } from "src/app/models/product-ui.model";
// import Swiper from 'swiper';

import { Swiper, SwiperOptions } from "swiper/types";
import {
  Observable,
  defer,
  firstValueFrom,
  from,
  map,
  shareReplay,
  switchMap,
  take,
  zip,
} from "rxjs";

@Component({
  selector: "app-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.scss"],
})
export class ProductListComponent
  implements ReceptorMessage, OnInit, AfterViewInit {
  // @Output() action = new EventEmitter<OutputMessage>();
  @ViewChild("swiperRef") swiperRef: ElementRef;
  swiper?: Swiper;
  @Input() newDesign!: boolean;
  @Input() relatedProduct: Product[] | _Product[] = [];
  @Input() limit = 5;
  @Input() lateralList = false;
  @Input() measurement = false;
  @Input() onlyFeatured = false;
  @Input() smallLabels = false;
  @Input() swiperConfig: SwiperOptions = {
    spaceBetween: 25,
    navigation: true,
    scrollbar: { draggable: true },
    keyboard: true,
    zoom: true,
    breakpoints: {
      // when window width is >= 320px
      520: {
        slidesPerView: 1.3,
      },
      650: {
        slidesPerView: 2.7,
      },
    }
  };
  products: Product[];
  productsT = [
    { title: "title1" },
    { title: "title2" },
    { title: "title3" },
    { title: "title4" },
    { title: "title5" },
  ];
  productLists: ProductList[];

  constructor(
    private productBlService: ProductBlService,
    private productDbService: ProductDbService,
    private shopBlService: ShopBlService,
    private ref: ChangeDetectorRef
  ) {
  }

  products$: Observable<Array<Product>>;

  //TODO J.A. esto se debería manejar desde un trigger o al editar el producto, igualmente unificar con el de la tabla de productos y no repetir esto
  productVariants$ = (product: Product) =>
    this.productDbService.getVariants$(product.id).pipe(
      map((variants) => {
        return {
          ...product,
          cheapestVariant: cheapestVariant(variants),
          highestDiscount: highestDiscount(variants),
          variants,
        };
      })
    );

  producLists$: Observable<ProductList[]> = defer(() =>
    this.products$.pipe(
      switchMap((products) => {
        const variants = products.map((product) =>
          this.productVariants$(product)
        ) as any as Array<Observable<ProductList>>;
        return zip(variants);
      })
    )
  );

  loading = true; // Bandera de carga

  async ngOnInit() {
    this.products$ = from(
      this.productDbService.getRelatedProducts(this.relatedProduct, this.limit)
    ).pipe(
      map(products => this.onlyFeatured ? products.filter(pr => pr.featured) : products),
      shareReplay(1)
    );
  }

  async ngAfterViewInit() {
    this.productLists = await firstValueFrom(this.producLists$.pipe(take(1)));
    setTimeout(() => {
      this.loading = false; // Los datos ya están cargados
      this.ref.markForCheck();
      const swiperEl = this.swiperRef.nativeElement;
      this.swiper = swiperEl.swiper;
      Object.assign(swiperEl, this.swiperConfig);
      setTimeout(() => swiperEl.initialize(), 10);
    }, 1000)

  }


  /**
   * Emits the first status of the product List
   * @param productCard
   */
  onAdd(productCard) {
    this.shopBlService.selectProduct(productCard, this.measurement);
  }

  // emitAction(type: string, value?: any): void { this.action.emit(new OutputMessage(type,value))}
  onAction(event: OutputMessage): void {
    event.invoke(this);
  }

  autoplayStop(force = false) {
    //TODO J.A.Pendiente de ver si es necesario y cual es la finalidad
    // const swiper = this.swiperComponent.swiperRef;
    // if (swiper.autoplay.running || force) {
    //   swiper.autoplay.stop();
    //   swiper.slideTo(swiper.activeIndex + 1, 300);
    //   const swiperHtml = document.getElementsByClassName('linear-swiper')[0];
    //   if (swiperHtml) swiperHtml.classList.remove('linear-swiper');
    //   this.swiperComponent.updateSwiper({ speed: 300 });
    // }
  }
}

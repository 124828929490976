// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-searchbar {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-bottom: 18px;
}

ion-searchbar.custom-searchbar {
  border: 1px solid #949494;
  border-radius: 30px;
  color: #949494;
  --icon-color: #949494;
  --placeholder-color: #949494;
  --placeholder-font-weight: 400;
}
ion-searchbar.custom-searchbar ion-icon {
  color: #949494;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlYXJjaC1iYXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSw2RUFBQTtFQUNBLG1CQUFBO0FBQ0Y7O0FBRUE7RUFDRSx5QkFBQTtFQUNBLG1CQUFBO0VBQ0EsY0FBQTtFQUVBLHFCQUFBO0VBQ0EsNEJBQUE7RUFDQSw4QkFBQTtBQUFGO0FBRUU7RUFDRSxjQUFBO0FBQUoiLCJmaWxlIjoic2VhcmNoLWJhci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImlvbi1zZWFyY2hiYXIge1xuICB0cmFuc2l0aW9uOiBiYWNrZ3JvdW5kIDAuM3MsIGJvcmRlciAwLjNzLCBib3JkZXItcmFkaXVzIDAuM3MsIGJveC1zaGFkb3cgMC4zcztcbiAgbWFyZ2luLWJvdHRvbTogMThweDtcbn1cblxuaW9uLXNlYXJjaGJhci5jdXN0b20tc2VhcmNoYmFyIHtcbiAgYm9yZGVyOiAxcHggc29saWQgIzk0OTQ5NDtcbiAgYm9yZGVyLXJhZGl1czogMzBweDtcbiAgY29sb3I6ICM5NDk0OTQ7XG5cbiAgLS1pY29uLWNvbG9yOiAjOTQ5NDk0O1xuICAtLXBsYWNlaG9sZGVyLWNvbG9yOiAjOTQ5NDk0O1xuICAtLXBsYWNlaG9sZGVyLWZvbnQtd2VpZ2h0OiA0MDA7XG5cbiAgaW9uLWljb24ge1xuICAgIGNvbG9yOiAjOTQ5NDk0O1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/search-bar/search-bar.component.scss"],"names":[],"mappings":"AAAA;EACE,6EAAA;EACA,mBAAA;AACF;;AAEA;EACE,yBAAA;EACA,mBAAA;EACA,cAAA;EAEA,qBAAA;EACA,4BAAA;EACA,8BAAA;AAAF;AAEE;EACE,cAAA;AAAJ;AACA,w4BAAw4B","sourcesContent":["ion-searchbar {\n  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;\n  margin-bottom: 18px;\n}\n\nion-searchbar.custom-searchbar {\n  border: 1px solid #949494;\n  border-radius: 30px;\n  color: #949494;\n\n  --icon-color: #949494;\n  --placeholder-color: #949494;\n  --placeholder-font-weight: 400;\n\n  ion-icon {\n    color: #949494;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

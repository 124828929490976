import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Product, ProductSubType } from 'src/app/models/product.model';
import { ProductBlService } from 'src/app/shared/services/productBl.service';
import { ProductHandle } from 'src/app/shop/productos/handler/product-handler';
import { EmmiterMessage, OutputMessage, ReceptorMessage } from 'src/app/models/models';

import { ProductPageModel } from 'src/app/models/product-ui.model';
import { QtyStepperModule } from '../../qty-stepper/qty-stepper.module';
import { CartService } from 'src/app/shared/cart.service';

@Component({
  selector: 'app-add-to-cart-steps',
  templateUrl: './add-to-cart-steps.component.html',
  styleUrls: ['./add-to-cart-steps.component.scss'],
})
export class AddToCartStepsComponent extends ProductHandle implements OnInit {

  @Input() product: ProductPageModel;
  @Input() inputVariantSelected;
  @Input() highestDiscount;
  @Input() lateralList: boolean;

  step: number = 0;
  backSubject = new Subject<void>();

  @Output() closeEvent = new EventEmitter<any>();
  @Output() action = new EventEmitter<OutputMessage>();

  protected readonly ProductSubType = ProductSubType;


  constructor(
    protected productBlService: ProductBlService,
    private cartService: CartService
  ) {
    super();

    /*this.product$.subscribe(product => {
      console.log("Product in suscription:", product);
      this.product = product;
      this.highestDiscount = highestDiscount(product.variants);
    });*/
  }

  ngOnInit() {
    //console.log('onInit input variantSelected', this.inputVariantSelected)

    this.checkFlexWrap();
    window.addEventListener('resize', this.checkFlexWrap);
  }

  ngAfterViewInit() {
    /*window.addEventListener('load', this.checkOverFlow);
    window.addEventListener('resize', this.checkOverFlow);
    this.checkOverFlow();*/
  }

  ngOnDestroy() {
    this.product.qty = 1;
  }

  emitClose() {
    this.closeEvent.emit();
  }

  emitAction(type: string, value?, event?: Event) {
    //console.log('when emit input variantSelected', this.variantSelectedYuhu$)
    //console.log("This product:", this.product)
    if (value.step)
      this.step = value.step;
    else
      this.action.emit(new OutputMessage(type, value));

    if (type === 'onAdd') {
      //console.log('product in onAdd', value)
      event.stopPropagation();
      this.emitClose();
    }
  }

  maxQtyToAdd(product: ProductPageModel) {
    const productInCart = this.cartService.cart.items.find(it => it.product.id === product.id && it.product.variantId === product.variantId)
    return productInCart ? product.stock - productInCart.qty : product.stock;
  }

  goBack() {
    this.step--;
    this.backSubject.next();
  }

  checkFlexWrap() {
    const flexContainer = document.querySelector('.flex-container') as HTMLElement;

    if (!flexContainer) return;

    const items = Array.from(flexContainer.children) as HTMLElement[];
    const tolerance = 5;

    let wrapped = false;
    let previousBottom = items[0].getBoundingClientRect().bottom;

    for (let i = 1; i < items.length; i++) {
      const currentBottom = items[i].getBoundingClientRect().bottom;
      if (Math.abs(currentBottom - previousBottom) > tolerance) {
        wrapped = true;
        break;
      }
    }

    if (wrapped) {
      flexContainer.classList.add('wrapped');
      flexContainer.classList.remove('no-wrapped');
    } else {
      flexContainer.classList.add('no-wrapped');
      flexContainer.classList.remove('wrapped');

    }
  }

  checkOverFlow() {
    const container = document.querySelector('.product-options') as HTMLDivElement;

    if (!container) return;

    const observer = new MutationObserver(() => {
      if (container.scrollHeight > container.clientHeight) {
        container.style.overflowY = 'auto'
      } else {
        container.style.overflowY = 'unset'
      }
    })

    observer.observe(container, { childList: true, subtree: true });

    if (container.scrollHeight > container.clientHeight) {
      container.style.overflowY = 'auto';
    } else {
      container.style.overflowY = 'unset';
    }

  }

  checkProductAvailable(product: ProductPageModel) {
    return (product?.allowSaleNoStock || product?.stock >= product.qty) && product.visible !== false
  }
}

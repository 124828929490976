import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CartService } from 'src/app/shared/cart.service';

@Component({
  selector: 'app-confirm-modal-cart',
  templateUrl: './confirm-modal-cart.component.html',
  styleUrls: ['./confirm-modal-cart.component.scss'],
})
export class ConfirmModalCartComponent implements OnInit {

  @Input() item;
  @Input() warningCoupon : boolean = false;
  @Input() deleteModal: boolean = false;
  @Input() bonusModal: boolean = false;
  folderName: any;
  useWallet: boolean = false;

  constructor(private modalController: ModalController, private cartService: CartService) { }

  ngOnInit() {}

  dismiss() {
    if (this.bonusModal) {
      this.useWallet = false;
    }
    this.modalController.dismiss({useWallet: this.useWallet});
  }

  onDelete() {
    this.cartService.deleteProductCart(this.item);
    this.dismiss();
  }

  close() {
    if (this.deleteModal) {
      this.cartService.deleteProductCart(this.item);
    }
    if (this.warningCoupon || this.bonusModal) {
      this.useWallet = true;
    }
    this.modalController.dismiss({useWallet: this.useWallet});
  }
}

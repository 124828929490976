import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { ShoppingCart } from 'src/app/models/cart.model';
import { Finishing, Settings } from 'src/app/models/models';
import { Product, ProductType, ProductSubType } from 'src/app/models/product.model';
import { CartService } from 'src/app/shared/cart.service';
import { ProductService } from 'src/app/shared/product.service';
import { ConfirmModalCartComponent } from 'src/app/components/confirm-modal-cart/confirm-modal-cart.component';

@Component({
  selector: 'app-item-shop-product',
  templateUrl: './item-shop-product.component.html',
  styleUrls: ['./item-shop-product.component.scss'],
})
export class ItemShopProductComponent implements OnInit {
  @Input() item: ShoppingCart.OrderItem;
  @Input() i;
  @Input() disableActions;
  @Input() showQty;

  viewDetails: boolean = false;

  customElementsToEs = {
    ['paperSize']: 'Tamaño de página',
    ['paperColor']: 'Color del papel',
    ['paperWeight']: 'Gramaje',
    ['paperPattern']: 'Motivo del papel',
    ['sheetNumber']: 'Número de folios',
    ['rings']: 'Anilla',
    ['coverFront']: 'Tapa delantera',
    ['coverBack']: 'Tapa trasera'
  }

  constructor(
    public cartService: CartService,
    private router: Router,
    private menuCtrl: MenuController,
    private productService: ProductService,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    //console.log('ItemShopProductComponent initialized');
  }

  async onDelete(item) {

    if (item.product.type === ProductType.WALLETBONUS) {
      this.cartService.applyWallet(false);
    }
    this.cartService.deleteProductCart(item);
  }

  onQtyChange(event) {

    const product = this.item.product as Product;
    const qty = event.qty - this.item.qty
    this.cartService.updateProductCart(product, qty)
  }

  /*onQtyChange(event) {
    const product = this.item.product as Product;
    const qty = event.qty - this.item.qty
    this.cartService.updateProductCart(this.item, qty)
  }*/

  formatProperties(props: object) {
    return Object.entries(props).map(([key, value]) => ({key, value}));
  }

  formatCustomElements(props: object) {
    const properties = [];
    Object.entries(props).forEach(([key, value]: [string, any]) => {
      properties.push({ key: this.customElementsToEs[key], value: value.name })
    });
    return properties;
  }

  protected readonly Product = Product;
  protected readonly ProductSubType = ProductSubType;

  toggleViewDetails() {
    this.viewDetails = !this.viewDetails;
  }
}

import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { EmmiterMessage, OutputMessage } from 'src/app/models/models';
import { ProductList, ProductPageModel } from 'src/app/models/product-ui.model';
import { CartService } from '../../cart.service';
import { MenuController, ModalController, ToastController } from '@ionic/angular';
import { AlertModalComponent } from 'src/app/components/alert-modal/alert-modal.component';
import { ProductBlService } from '../../services/productBl.service';
import { ProductHandle, productSelected$ } from 'src/app/shop/productos/handler/product-handler';
import { map, shareReplay, Subject, takeUntil } from 'rxjs';
import highestDiscount = Product.highestDiscount;
import { Product } from 'src/app/models/product.model';
import { ProductService } from '../../product.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export default class ProductCardComponent extends ProductHandle implements OnInit, OnChanges, EmmiterMessage {

  @ViewChild('card') card: ElementRef;

  @Input() product;
  @Input() lateralList = false;
  @Input() smallLabels = false;
  @Input() context: string;
  @Output() action = new EventEmitter<OutputMessage>();
  @Output() updateOpenedCardProduct = new EventEmitter<any>();

  private componentDestroyed = new Subject();

  cartSteps: boolean = false;

  elementHover = false;
  optionsSelected = null;
  highestDiscount = 0;

  addedToCartConfirmation: boolean = false;

  constructor(
    public cartService: CartService,
    public modalController: ModalController,
    public productBlService: ProductBlService,
    public toastController: ToastController,
    public productService: ProductService,
    private router: Router,
    public menuCtrl: MenuController
  ) {
    super();

  }


  ngOnInit(): void {
    this.product$ = this.productBlService.productWithVariants$(this.product.id).pipe(shareReplay(1));

    this.product$.pipe(
      map(product => {
        const variantId = this._variantSelected.value?.id;
        //console.log('variant selected', this._variantSelected.value)
        if (!product) return null;
        //this.product = product;
        //this.highestDiscount = highestDiscount(product.variants)
        // console.log("__________",variantId);
        return variantId ? product.variants.find(variant => variant.id === variantId) : product.variants?.length ? product.variants[0] : null
      }),
      takeUntil(this.componentDestroyed)
    ).subscribe(this._variantSelected);

    this.productSelected$ = productSelected$(this.product$, this._qty, this._variantSelected);

    
  }

  ngOnChanges(): void {
    // console.log("Producto: ",this.product);
  }

  onQtyChange(event) {
    this._qty.next(event.qty);
  }

  async onAdd(product: ProductPageModel) {
    // console.log("Se deben colocar los cambios")
    if ((this.cartService.cart.items.some(it => !it.product.presale) && product.presale) ||
      (this.cartService.cart.items.some(it => it.product.presale) && !product.presale)) {
      const modal = await this.modalController.create({
        component: AlertModalComponent, // AddressComponent,
        componentProps: {
          msg: 'Con el fin de poder respetar los tiempos de entrega no es posible combinar otros productos junto a un producto en preventa, ya que este se encuentra en proceso de producción. Por favor realiza 2 pedidos independientes.'
        },
        cssClass: 'auto-height'
      });
      await modal.present();
      return;
    }
    this.productBlService.addProduct(product, this._qty.value);
    //this.dismiss();
    // const toast = await this.toastController.create({
    //   message: 'El producto se ha añadido al carrito',
    //   duration: 4000,
    //   color: 'primary',
    //   cssClass: 'fitWidthToast'
    // });
    // await toast.present();

    this.addedToCartConfirmation = true;
    //console.log('confirmation 1', this.addedToCartConfirmation);
    await setTimeout(() => {
      this.addedToCartConfirmation = false;
      //console.log('confirmation 2', this.addedToCartConfirmation);
    }, 5000);
  }

  onVariantChange(event) {
    this._variantSelected.next(event.variant);
  }

  onUpdateOptionsSelected(event) {
    this.optionsSelected = event;
  }

  public onAction(event: OutputMessage) {
    event.invoke(this);
  }

  emitAction(type: string, value?: any): void {
    this.action.emit(new OutputMessage(type, value));
  }

  setElementHover(status) {
    this.elementHover = status;
  }

  /**
   * It is necessary to know the context of the product card to avoid opening
   * the same card in all contexts, as it is the same card instance in different
   * contexts.
   * Therefore, this method updates in ProductService the opened product.
   * In case of custom products, the user is redirected to its page to customize
   * the product before being able to add it to the cart.
   * @param context 
   * @returns 
   */
  updateOpenedProduct(context: string) {
    //this.updateOpenedCardProduct.emit({product: this.product});

    if (this.product.isCustom || this.product.subType === 'custom' || this.product.customType === 'noteBook') {
      this.router.navigate(['/papeleria/catalogo', this.product.slug]);
      if (this.lateralList) {
        this.menuCtrl.close('cart-preview');
      }
      return;
    }

    this.productService.productOpened = {product: null, context: null};
    this.productService.productOpened = {product: this.product, context: context};
  }

  openCartSteps(context: string) {
    //return this.openedCardProduct?.id === this.product.id;
    //let sameProduct = this.productService.productOpened?.id === this.product.id;
    const { product, context: openContext } = this.productService.productOpened || {product: null, context: null};

    return product?.id === this.product.id && openContext === context;
  }

  close() {
    //this.updateOpenedCardProduct.emit({product: null});
    this.productService.productOpened = {product: null, context: null};
  }

  // Intento de cerrar la tarjeta cuando se hace click fuera de ella
  /*@HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const clickedInside = this.card.nativeElement.contains(event.target);

    if (!clickedInside) {
      this.close();
    }
  }*/
}

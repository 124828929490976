import { Component, OnInit } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { VersionService } from "../../core/version.service";
import { AppService } from "../../shared/app.service";

@Component({
  selector: 'app-orders-blocked-alert',
  templateUrl: './orders-blocked-alert.component.html',
  styleUrls: ['./orders-blocked-alert.component.scss'],
})
export class OrdersBlockedAlertComponent implements OnInit {

  constructor(private modalController: ModalController, public appService: AppService) {
  }

  ngOnInit() {
  }

  close() {
    this.modalController.dismiss()
  }

}

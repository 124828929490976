import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken, Renderer2 } from '@angular/core';

export const LOG_LEVEL = new InjectionToken<Array<number>>('LOG_LEVEL');

export namespace log  {
  export const info =(...msg)=>{
    const logLevel = inject(LOG_LEVEL);
    if(logLevel.includes(1)){
      console.log(...msg)
    }
  }
  export const error = (...msg)=>{
    const logLevel = inject(LOG_LEVEL);
    if(logLevel.includes(2)){
      console.error(...msg);
    }
  }
}

// window.console.log = () =>{};
// window.console.error = () =>{};


export const loadJsScript = (src: string): HTMLScriptElement => {
  const document = inject(DOCUMENT);
  const renderer = inject(Renderer2);
  const script = renderer.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  renderer.appendChild(document.body, script);
  return script;
}
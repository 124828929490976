import { Injectable, signal } from '@angular/core';
import {
  _PrintFile,
  Discount,
  DiscountType,
  Finishing,
  Order,
  OrderType,
  PaymentType,
  PaperWeight,
  PrintFileStatus,
  PrintingGroup,
  Settings
} from '../models/models';
import _, { cloneDeep, isEqual } from 'lodash';
import { ProductService } from './product.service';
import { Coupon, CouponType } from '../coupons/coupon.models';
import { WalletService } from '../wallet/wallet.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { BehaviorSubject, firstValueFrom, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { ShoppingCart } from '../models/cart.model';
import { round2, uidI } from './utils/utils';
import { Product, ProductCustomType, ProductSubType, ProductType } from '../models/product.model';
import { ShippingService } from './services/shipping.service';
import { AppCoreService } from '../core/app-core.service';
import { first, tap } from 'rxjs/operators';
import { ShippingType } from '../models/shipping-settings.model';
import { AppService } from './app.service';
import { ProductDbService } from './database/product-db.service';
import { MenuController, ModalController, ToastController } from '@ionic/angular';
import { PromotionDialogComponent } from './promotion-dialog/promotion-dialog.component';
import { PopupBeforePayComponent } from './popup-before-pay/popup-before-pay.component';
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { PresaleWarningComponent } from "./presale-warning/presale-warning.component";
import { SettingService } from "./setting.service";
import { Router } from "@angular/router";
import { EncryptionService } from "./encryption.service";
import { PrintProductStateService } from "./print-product-state.service";
import { EventNameString } from 'firebase/analytics';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { ConfirmModalCartComponent } from '../components/confirm-modal-cart/confirm-modal-cart.component';
import { ConfirmCommentModalComponent } from "../components/confirm-comment-modal/confirm-comment-modal.component";
import { CookieService } from "ngx-cookie-service";
import { FileService } from "./file.service";
import { OrderStateService } from './order-state.service';

/**
 * Todas las funciones del carrito
 *
 */
@Injectable({
  providedIn: 'root'
})

export class CartService {
  isFirstCoupon: boolean = false;
  freeShippingAmount = 35;
  shippingProductPrice: number; // sum of all products that sum up for the shipping to be free
  _coupon: Coupon;
  cartProductObservables: Map<string, { observable: Observable<Product>, subscription: Subscription }> = new Map();
  API_VALIDATE_FILES = 'api2/orders/validateFiles';
  cartBindingStockUses = { rings: [], covers: [] };
  bindingStockExceeded = { rings: [], covers: [] };
  bindingStock = { rings: [], covers: [] };

  get coupon(): Coupon {
    return this._coupon;
  }

  set coupon(c) {
    this._coupon = c;
  }

  userCoupon: Coupon;
  hasPrintProducts: boolean = false; // does the cart has any printed products
  walletDiscountable: number;
  discountWallet: boolean;
  canUseCoupon: boolean = false;
  applyUserCoupon$ = new ReplaySubject<string>();
  orderForm;
  postalCode = '28001';
  shippingType = null;
  order: Order = null;
  clonnedOrder: Order = null; // copy of the finished order before emptying the cart, used to show order info in confirmation step
  cart: ShoppingCart;
  generalSettings;
  onlyWalletBonus = true;
  public freeShipping = false;
  private refreshCartSubject = new Subject<any>();

  currentStep = 0;
  walletDeselected: boolean;
  userIsGuest: boolean = false;

  arePaymentOptionsAvailable = signal(true);
  selectedPaymentType: PaymentType;

  walletDiscountClonnedOrder;


  constructor(
    private productService: ProductService,
    private walletService: WalletService,
    private analytics: AngularFireAnalytics,
    private shippingService: ShippingService,
    private appCoreService: AppCoreService,
    private appService: AppService,
    private productDbService: ProductDbService,
    public modalController: ModalController,
    private functions: AngularFireFunctions,
    private settingService: SettingService,
    private toastController: ToastController,
    private router: Router,
    private encryptS: EncryptionService,
    private printProductStateService: PrintProductStateService,
    private cookieService: CookieService,
    private menuCtrl: MenuController,
    private fileService: FileService,
  ) {
    //this.cart = CART as any;
    this.appCoreService.isAppReady$.pipe(
      first(isAppReady => isAppReady)
    )
      .subscribe(isAppReady => this.initCart());
  }


  async initCart() {
    // setInterval(() => {
    //   console.log('cart', this.cart)
    // }, 4000)
    this.coupon = null;
    this.discountWallet = false;
    this.walletDiscountable = 0;
    this.cart = {
      createdAt: new Date,
      updatedAt: new Date,
      items: [],
      shipping: {
        price: { amt: 0, cur: 'EUR' },
        weight: 0
      },
      price: {
        cur: 'EUR',
        taxPct: 21,
        noTaxAmt: 0,
        taxAmt: 0,
        totalAmt: 0
      },
      discounts: []
    };
    this.shippingType = null;
    const savedCart = this.loadCart();
    if (savedCart) {
      this.cart = savedCart;
      this.setProductUpdates();
      this.checkPrintFiles();
    }
    await this.cleanCart()
    this.refreshCartPrice(true)

    this.settingService.getSettings('general').pipe(
      tap((data) => {
        this.generalSettings = data;
      })
    ).subscribe();

    this.settingService.getSettings('admin')
      .subscribe((admin) => {
        this.bindingStock = admin.bindingStock;
        this.refreshPrintProductsBindingUses();
      });

    this.walletService.applyWallet$.subscribe(status => {
      this.applyWallet(this.discountWallet || status);
    });
  }

  add(item: ShoppingCart.OrderItemUi) {
    const np = cloneDeep(item);
    this.refreshCartPrice();
  }

  del(item: ShoppingCart.OrderItemUi) {
    //No funciona para los prins que tienen varios item con el mismo id
    const cartIndex = this.cart.items.findIndex(_item => _item.product.id === item.product.id);
    this.cart.items.splice(cartIndex, 1);
    this.refreshCartPrice();
  }


  ///////// NEWS FUNCTIONS /////////////
  setProductCart(product: Product, qty: number) {
    const cartItem = this.findProductCart(product);
    if (!qty && cartItem) {
      this.deleteProductCart(cartItem);
    }
    if (qty) {
      if (cartItem) {
        this.updateProductCart(product, qty);
      } else {
        this.addProductCart(product, qty);
      }
    }
  }

  findProductCart(product): ShoppingCart.OrderItem {
    if (product?.subType === ProductSubType.CUSTOM) {
      return this.cart.items.find(_item =>
        _item.product.id === product.id &&
        (product.variantId ? _item.product.variantId === product.variantId : true) &&
        _.isEqual(_item.product.customElementsSelected, product.customElementsSelected)
      );
    } else {
      return this.cart.items.find(_item => (_item.product.id === product.id && _item.product.variantId == product.variantId));
    }
  }

  addProductCart(product: Product, qty: number) {
    if (product.type === ProductType.WALLETBONUS) {
      this.walletDeselected = false;
    }
    this.cart.items.push(ShoppingCart.newOrderItem(product, qty));
    this.setProductUpdates();
    this.refreshCartPrice();
  }

  updateProductCart(product: Product, qty: number) {
    const index = this.cart.items.findIndex(_item =>
      _item.product.subType === ProductSubType.CUSTOM && _item.product.customType === ProductCustomType.NOTEBOOK ?
        isEqual(_item.product.customElementsSelected, product.customElementsSelected) :
        _item.product.id === product.id && _item.product.variantId == product.variantId
    );

    const cartItem = this.cart.items[index];
    const newQty = qty + cartItem.qty;
    const newCartItem = ShoppingCart.newOrderItem(product, newQty);
    // newCartItem.price.amt = newCartItem.price.amt + cartItem.price.amt;
    // newCartItem.qty = newCartItem.qty + cartItem.qty;
    this.cart.items[index] = newCartItem;
    this.refreshCartPrice()
  }

  /*updateProductCart(item: ShoppingCart.OrderItem, qty: number) {
    const index = this.cart.items.findIndex(_item => _item.product.id === item.product.id && _item.product.variantId == item.product.variantId);
    const cartItem = this.cart.items[index];
    const newQty = qty + cartItem.qty;
    const newCartItem = ShoppingCart.newOrderItem(item.product, newQty);
    // newCartItem.price.amt = newCartItem.price.amt + cartItem.price.amt;
    // newCartItem.qty = newCartItem.qty + cartItem.qty;
    this.cart.items[index] = newCartItem;
    this.refreshCartPrice()
  }*/


  deleteProductCart(cartItem: ShoppingCart.OrderItem) {
    const index = this.cart.items.findIndex(item => item.cartId === cartItem.cartId);
    this.cart.items.splice(index, 1);
    this.refreshCartPrice();
    this.setProductUpdates();
  }

  ////////////// END NEW FUNCTIONS ////////


  update(item: ShoppingCart.OrderItemUi) {
    const np = item;
    const add: EventNameString = 'add_to_cart';
    const items = {
      item_id: item.product.id,
      item_name: item.product.title,
      item_category: item.product.type,
      // item_variant: 'black',
      item_brand: 'copyfly',
      price: item.product.unitPrice.amt,
      quantity: item.qty
    };
    const event = {
      currency: item.price.cur,
      value: item.price.amt,
      items: [items] //item.product es solo 1, a pesar de estar en un array
    };

    if (item.cartId === undefined) {
      if (item.qty > 0) {
        this.add(item);
        this.analytics.logEvent(add, event);
      }
      ;

    } else {
      if (item.qty > 0) {
        const cartIndex = this.cart.items.findIndex(_item => _item.product.id === item.product.id);
        this.cart.items[cartIndex].qty = item.qty;
        this.cart.items[cartIndex].price = item.price;
        this.refreshCartPrice();

        const itemsAll = this.cart.items.map(item => {
          return {
            item_id: item.product.id,
            item_name: item.product.title,
            item_category: item.product.type,
            // item_variant: 'black',
            item_brand: 'copyfly',
            price: item.product.unitPrice.amt,
            quantity: item.qty
          };
        });
        const eventAll = {
          currency: this.cart.price.cur,
          value: this.cart.price.totalAmt,
          items: [itemsAll]
        };

        this.analytics.logEvent(add, eventAll);

      } else {
        this.del(item);

        const remove: EventNameString = 'remove_from_cart';
        this.analytics.logEvent(remove, event);
      }
    }
  }

  getStoreProductsInCart() {
    return this.cart.items.filter(item => item.product.type === ProductType.STORE);
  }

  getPrintProductsInCart() {
    return this.cart.items.filter(item => item.product.type === ProductType.PRINT);
  }

  refreshCartPrice(refreshPrintingGroups = false) {
    //verificamos el estatus de los cupones y las condiciones y si hay que aplicar el cupon de usuario
    //retornamos sin refrescar precios hasta que se aplique el cupón
    if (this.setCouponStatus()) {
      return;
    }
    let fullPrice = 0;
    this.hasPrintProducts = false;
    this.checkCartTaxes(this.addressIsCanary());
    this.cart.items.forEach(item => {
      // const royalty = item.product.royalty ?? 0;

      const royalty = 0;
      fullPrice += round2(item.price.amt + royalty);
      if (item.product.type == ProductType.PRINT) {
        if (refreshPrintingGroups) {
          this.productService.setPrintProduct(item, item.product.printingGroup.files)
          fullPrice += round2(item.price.amt + royalty);
        }
        item.product.copyPrice = this.productService.getPriceOption(item.product.printingGroup.printSettings);
        this.hasPrintProducts = true;
      }
    });

    if (!this.hasPrintProducts) {
      this.coupon = null;
    }
    this.applyDiscount();

    this.setShipping();

    fullPrice += round2(this.cart.shipping.price.amt);
    const discount = this.getDiscount();
    fullPrice += discount;

    //Actualizamos los Totales del Carrito
    // this.cart.price.noTaxAmt = fullPrice / (1 +  this.cart.price.taxPct / 100);
    // this.cart.price.taxAmt = fullPrice - this.cart.price.noTaxAmt;
    // this.cart.price.totalAmt = fullPrice;
    this.cart.price.noTaxAmt = round2(fullPrice / (1 + this.cart.price.taxPct / 100));
    this.cart.price.taxAmt = round2(fullPrice - this.cart.price.noTaxAmt);
    this.cart.price.totalAmt = round2(fullPrice);
    this.refreshPrintProductsBindingUses();
    this.saveCart(this.cart);
    if (!this.cart.price.totalAmt && !this.coupon) {
      this.refreshCartSubject.next({ paymentType: PaymentType.FULLBONUS });
    } else {
      if (this.order?.paymentType == PaymentType.FULLBONUS) {
        this.refreshCartSubject.next({ paymentType: PaymentType.CREDITCARD });
      }
    }
    this.refreshCartSubject.next(null);
  }

  onCartRefreshed() {
    return this.refreshCartSubject.asObservable();
  }

  addressIsCanary() {
    return ['38', '35'].includes(this.postalCode?.substring(0, 2))
  }

  addressIsCanaryOrBalearics() {
    return ['38', '35', '07'].includes(this.postalCode?.substring(0, 2))
  }

  setProductUpdates() {
    const storeItems = this.cart.items.filter(it => ['store', 'walletbonus'].includes(it.product.type));
    const ids = storeItems.map(it => ({ productId: it.product.id, variantId: it.product.variantId }));
    // const variantIds = storeItems.filter(it => it.product.variantId).map(it => it.product.variantId);
    ids.forEach(id => {
      if (!this.cartProductObservables.has(id.productId)) {
        const productChanges$ = this.productDbService.onProductChange(id.productId);
        const subscription = productChanges$.subscribe(prodData => {
          this.onStoreProductInCartChanged({ id: id.productId, ...prodData });
        });
        this.cartProductObservables.set(id.productId, { observable: productChanges$, subscription });
      }

      if (id.variantId) {
        if (!this.cartProductObservables.has(id.variantId)) {
          const variantChanges$ = this.productDbService.onVariantChange(id.productId, id.variantId);
          const subscription = variantChanges$.subscribe(variantData => {
            this.onStoreProductInCartChanged({ id: id.productId, variantId: id.variantId, ...variantData }, true);
          });
          this.cartProductObservables.set(id.variantId, { observable: variantChanges$, subscription });
        }
      }
    });

    this.cartProductObservables.forEach((value, id) => {
      if (!this.cart.items.some(item => item.product.id === id)
        && !this.cart.items.some(item => item.product.variantId === id)) {
        value.subscription.unsubscribe();
        this.cartProductObservables.delete(id);
      }
    });

  }

  async onStoreProductInCartChanged(data, variant = false) {
    let index = this.cart.items.indexOf(this.findProductCart(data));
    if (variant) {
      index = this.cart.items.indexOf(this.cart.items.find(item => item.product.variantId === data.variantId));
    }
    if (index !== -1) {
      const oldItem = this.cart.items[index];
      if (oldItem.product.subType === ProductSubType.CUSTOM) {
        const customElements = oldItem.product.customElementsSelected;
        oldItem.product.unitPrice.amt = await this.productService.getCustomProductPrice(data.unitPrice.amt, customElements)
        oldItem.product.weight = await this.productService.getCustomProductWeight(customElements)
      }
      this.cart.items[index] = ShoppingCart.newOrderItem(data, this.cart.items[index].qty, oldItem.product);
    }
    this.refreshCartPrice();
  }

  checkPrintGroupExist(item: ShoppingCart.OrderItemUi) {
    return this.cart.items.find(_item => _item == item);
  }

  addPrintProduct(item: ShoppingCart.OrderItemUi) {
    item.cartId = uidI();
    item.product.folderName = `Carpeta ${this.getPrintProductsInCart().length + 1}`
    this.cart.items.push(item);
    this.refreshCartPrice();
  }

  delPrintProduct(item: ShoppingCart.OrderItemUi) {
    const cartIndex = this.cart.items.findIndex(_item => _item.cartId === item.cartId);
    this.cart.items.splice(cartIndex, 1);
    this.refreshCartPrice();
  }

  setShipping() {
    const { weight, amt, pP, fP } = this.getShippingPrice(this.postalCode, this.cart, this.shippingType);
    this.shippingProductPrice = pP;
    this.cart.shipping = { weight, price: { amt, cur: 'EUR' }, fullPrice: { amt: fP, cur: 'EUR' } };
    // Si el cupón es de tipo freeshipping se descuenta el envío
    if (this.coupon && this.coupon?.freeShipping) {
      const { amt } = this.getShippingPrice(this.postalCode, this.cart, ShippingType.STANDARD);

      const currentDiscount = this.cart.discounts.find(d => d.type = DiscountType.COUPON)
      if (currentDiscount) {
        this.cart.discounts.find(d => d.type = DiscountType.COUPON).amount -= amt;
      } else {
        const discount = {
          type: DiscountType.COUPON,
          amount: -amt,
          currency: 'EUR',
          refId: this.coupon.code
        };
        this.cart.discounts.push(discount)
      }
    }
  }

  /**
   * Calcula el precio del shipping recorriendo los items del carrito
   * @param postalCode
   * @param cart carro con los items
   * @param shippingType tipo de envio
   */
  public getShippingPrice(postalCode, cart, shippingType) {
    let weight = 0;
    let pP = 0; // ProducPrice
    this.onlyWalletBonus = true;

    //TODO CHECK cuidado tenemos que cambiarlo y hacer que se haga desde la ordeState
    // const postalCode =  this.orderForm ? this.orderForm.controls.shippingAddress.controls.postalCode.value: null;
    // console.log("=============================>>>> setShipping - PostalCode", postalCode);
    cart.items.forEach(item => {
      weight += this.productService.getProductWeight(item.product) * item.qty;
      pP += (item.product.type !== ProductType.WALLETBONUS) ? item.price.amt : 0;
      if (item.product.type !== ProductType.WALLETBONUS) {
        this.onlyWalletBonus = false;
      }
    });
    //Verificamos si lo comprado por impresión es > a lo especificado a ver si sale gratis
    // TODO: SEGURO QUE NO QUERRÁN ENVÍO GRATIS SI ES A MELILLA..... AUNQUE PASE DE freeShippingAmount
    const freeShippingAmount = this.getFreeShippingAmount(postalCode);

    //Precio full del envío
    let weightShippingPrice = this.shippingService.getWeightShippingPrice(weight, postalCode);
    let shippingPriceByRange = 0;
    if (!['07', '38', '35'].includes(postalCode?.substring(0, 2))) {
      weightShippingPrice = 0;
      shippingPriceByRange = this.shippingService.getRangeShippingPrice(pP);
    } else {
      shippingPriceByRange = this.shippingService.getWeightShippingPrice(weight, postalCode);
    }

    //Precio de envío en caso que aplique el descuento
    const freeShippingPrice = this.shippingService.getFreeShippingPrice(weight, postalCode);

    //Si cumple la condicion se aplica el precio reducido
    this.freeShipping = (freeShippingAmount && Number((pP - Math.abs(this.getDiscount(DiscountType.COUPON))).toFixed(2)) >= freeShippingAmount);
    let amt = this.freeShipping
      ? freeShippingPrice
      : shippingPriceByRange;
    // : shippingPriceByRange + weightShippingPrice;
    if (shippingType === ShippingType.PICKUP || (this.clonnedOrder ?? this.order)?.type === OrderType.COMPLEMENTARY || ((this.clonnedOrder ?? this).cart.items.length && this.onlyWalletBonus)
      || (this.clonnedOrder ?? this).cart.items.every(it => it?.product?.freeShipping)) {
      amt = 0;
      weightShippingPrice = 0;
    }

    if (shippingType === ShippingType.STANDARD_24) {
      amt += this.shippingService.shippingSettings.extra_24;
      weightShippingPrice += this.shippingService.shippingSettings.extra_24;
    } else if (shippingType === ShippingType.URGENT) {
      amt += this.shippingService.shippingSettings.extra_urgent;
      weightShippingPrice += this.shippingService.shippingSettings.extra_urgent;
    } else if (shippingType === ShippingType.ECONOMIC && amt !== 0) {
      amt -= this.shippingService.shippingSettings.extra_economic;
      weightShippingPrice -= this.shippingService.shippingSettings.extra_economic;
    }
    if (this.freeShipping) {
      if (shippingType === ShippingType.ECONOMIC) {
        amt = 0;
      }
      // if (shippingType === ShippingType.STANDARD_24) {
      //   amt = 4.99;
      // } else if (shippingType === ShippingType.URGENT) {
      //   amt = 8.99;
      // }
    }
    return { weight, amt, pP, fP: weightShippingPrice };
  }

  applyWallet(status) {
    if (this.walletService.wallet.accumulatedDiscount > 0 || this.walletService.wallet.forcedToUse) {
      status = true
    }
    this.discountWallet = status;
    this.walletDeselected = !status;
    this.refreshCartPrice();
  }

  applyDiscount() {
    // console.log("@@@Aply discount");
    const items = [];
    const discounts = this.productService.discounts;
    this.cart.discounts = [];
    const couponDiscount: Discount = {
      type: DiscountType.COUPON,
      amount: 0,
      currency: 'EUR',
      refId: null
    };
    const walletDiscount: Discount = {
      type: DiscountType.WALLET,
      amount: 0,
      currency: 'EUR',
      refId: null
    };
    this.cart.items.forEach(item => {
      items.push(cloneDeep(item));
    });

    //Aplicamos coupon si existe;
    if (this.coupon) {
      //Obtener el valor
      let couponBalance = this.coupon.amount;
      let discountRange = null;
      const discountByRanges = this.coupon.discountByRanges;
      const discountRanges = this.coupon.discountRanges;
      if (discountByRanges) {
        let cartBalance = 0;
        this.cart.items.forEach(item => {
          cartBalance += (item.product.type !== ProductType.WALLETBONUS) ? item.price.amt : 0;
        });
        for (let i = 0; i < discountRanges.length; i++) {
          if (discountRanges[i].cartPrice <= cartBalance) {
            discountRange = discountRanges[i];
          } else {
            break;
          }
        }
        if (discountRange) {
          couponBalance = discountRange.amount;
        }
      }

      //Si es pct recorrer todo y sacar el balance
      //Recorremos todos los items a los que se le puede aplicar el descuento y sacamos el total para ir descontando.
      //Factorizar en el futuro y unificar con el fixed.
      if ((this.coupon.type !== CouponType.FIXED && !discountRange) || (discountRange && discountRange.type !== CouponType.FIXED)) {
        couponBalance = 0;
        const couponAmount = discountRange ? discountRange.amount : this.coupon.amount;
        const couponMaxAmount = discountRange ? discountRange.maxDiscount : this.coupon.maxAmount;
        items.forEach(item => {
          if (discounts[item.product.type][DiscountType.COUPON]) {
            let bindingDiscount = this.coupon.bindingDiscountEnabled;

            if (bindingDiscount === null || bindingDiscount === undefined) {
              bindingDiscount = this.generalSettings.bindingDiscountEnabled === null || this.generalSettings.bindingDiscountEnabled === undefined ? true : this.generalSettings.bindingDiscountEnabled;
            }
            const bindingPrice = !!item.product.printingGroup.binding ? item.product.extra.price * item.qty : 0;
            couponBalance += bindingDiscount ? (item.price.amt * couponAmount / 100) : ((item.price.amt - bindingPrice) * couponAmount / 100);
          }
        });
        if ((couponMaxAmount && couponBalance > couponMaxAmount) || (!couponMaxAmount && couponBalance > (couponAmount * this.getFreeShippingAmount('30') / 100) && !discountRange)) {
          couponBalance = couponMaxAmount ? couponMaxAmount : (couponAmount * this.getFreeShippingAmount('30') / 100);
        }
        // console.log("couponBalance1", couponBalance)
      }

      //Aplicar coupon primero a los que son solo cupon (por ahora no ocurre, cuando sea necesario lo implementamos)
      // Ojo no borrar es para cuando exista el caso
      // if (discounts[item.product.type][DiscountType.COUPON]
      //   && !discounts[item.product.type][DiscountType.WALLET]){
      //     //Cuando exista el caso lo aplicamos
      // }

      //Aplicar resto a los que son varios
      items.forEach(item => {
        if (discounts[item.product.type][DiscountType.COUPON] && couponBalance) {
          //Descontamos de items el monto del cupon
          if (item.price.amt > couponBalance) {
            item.price.amt -= couponBalance;
            couponDiscount.amount -= couponBalance;
            couponBalance = 0;
          } else {
            let roundedAmt = round2(item.price.amt);
            couponBalance -= roundedAmt;
            couponDiscount.amount -= roundedAmt;
            item.price.amt = 0;
          }
        }
      });


      if (couponDiscount.amount < 0) {
        couponDiscount.refId = this.coupon.code;
        couponDiscount.amount = round2(couponDiscount.amount)
        this.cart.discounts.push(couponDiscount);
      }
    }

    let totalWallet = this.getFutureWallet() + this.walletService.wallet.balance;
    //Calculamos el monto descontable del Monedero
    this.walletDiscountable = 0;
    items.forEach(item => {
      if (discounts[item.product.type][DiscountType.WALLET]) {
        this.walletDiscountable -= round2(item.price.amt);
      }
    });
    //Agregamos el monto del envío si no tiene un cupón de freeShipping
    if (!this.coupon?.freeShipping) {
      this.walletDiscountable -= this.cart.shipping.price.amt;
    }


    //Si es mayor al total wallet colocamos el monto del wallet
    if (-this.walletDiscountable > totalWallet) {
      this.walletDiscountable = -totalWallet;
    }


    //Descontamos del monedero el monto de los items
    if (this.discountWallet && this.walletDiscountable < 0) {
      if (-this.walletDiscountable > totalWallet) {
        walletDiscount.amount -= totalWallet;
      } else {
        walletDiscount.amount += this.walletDiscountable;
      }
      walletDiscount.amount = round2(walletDiscount.amount);
    }

    if (walletDiscount.amount < 0) {
      this.cart.discounts.push(walletDiscount);
    }
  }

  getDiscount(type?) {
    return (this.clonnedOrder ?? this).cart.discounts.reduce((prev, discount) => {
      if (type) {
        return (discount.type == type) ? prev + discount.amount : prev;
      } else {
        return prev + discount.amount;
      }
    }, 0);
  }

  getFutureWallet() {
    return (this.clonnedOrder ?? this).cart.items.reduce((prev, item) => {
      return (item.product.type === ProductType.WALLETBONUS) ? (item.product.extra.amount + prev) * item.qty : prev;
    }, 0);
  }

  setCouponStatus() {
    let ret = false;
    const hasWalletBonus = this.cart.items.find(item => item.product.type === ProductType.WALLETBONUS);
    const hastPrintProduct = this.cart.items.find(item => item.product.type === ProductType.PRINT);
    const hasWallet = this.walletService.wallet.balance > 0 && this.discountWallet;
    //const hasWallet = this.walletService.wallet.balance !== 0;

    const canUseCoupon = hastPrintProduct && !hasWalletBonus && !hasWallet;


    // VIEJO
    // if (!this.canUseCoupon && canUseCoupon){
    //   this.canUseCoupon = canUseCoupon;
    //   if (this.userCoupon){ //si se activa el cupon y tiene cupon de usuario
    //     ret = true;
    //     //Aplicar el cupon
    //     console.log("_____COLOCO CUPON DE USUARIO______");
    //     this.applyUserCoupon$.next("userCoupon");
    //   }
    // }

    if (!this.canUseCoupon && canUseCoupon) {
      this.canUseCoupon = canUseCoupon;
    }

    //si es primer cupón
    if (!this.isFirstCoupon && this.canUseCoupon && !this.coupon && this.userCoupon) { //si se activa el cupon y tiene cupon de usuario
      this.isFirstCoupon = true;
      // console.log("Asignando el primer cupón", this.userCoupon);
      ret = true;
      //Aplicar el cupon
      this.applyUserCoupon$.next('userCoupon');
    }

    //Si se desactiva el cupon, se pone en ull
    if (this.canUseCoupon && !canUseCoupon) {
      this.canUseCoupon = canUseCoupon;
      if (this.discountWallet && this.coupon) {
        this.warningBonusSelected();
      } else {
        this.coupon = null;
      }
      this.isFirstCoupon = false;
    }

    return ret;
  }

  getFreeShippingAmount(postalCode?) {
    return this.shippingService.freeShipping(postalCode);
  }

  getShippingInfo(postalCode) {
    return this.shippingService.shippingInfo(postalCode);
  }

  resetCart() {
    this.removeCart();
    this.initCart();
  }

  saveCart(cart: ShoppingCart) {
    //Si no estan finished no se uardan
    if (this.filesFinished()) {
      localStorage.setItem('cart', this.encryptS.encrypt(JSON.stringify(cart)));
    }
  }

  loadCart() {
    try {
      const cart = JSON.parse(this.encryptS.decrypt(localStorage.getItem('cart')));
      this.appService.upgradeCart(cart);
      return cart;

    } catch (e) {
      localStorage.removeItem('cart');
      return;
    }
  }

  removeCart() {
    localStorage.removeItem('cart');
    this.cookieService.delete('bindingSelected')
  }

  filesFinished() {
    return !this.cart.items
      .filter(item => item.product.type === ProductType.PRINT)
      .find(item => item.product.printingGroup.files.find(file => file.status != PrintFileStatus.FINISHED || file.printSheets === 0));
  }

  async cleanCart() {
    const promises = this.cart.items.map(async item => {
      if (item.product && item.product.printingGroup && Array.isArray(item.product.printingGroup.files)) {
        // Filtrar los archivos para mantener solo aquellos con status igual a "finished"
        item.product.printingGroup.files = item.product.printingGroup.files.filter(file => file.status === PrintFileStatus.FINISHED
          && file.printSheets !== 0);
        await this.addMissingProperties(item.product.printingGroup);
      }
    });
    await Promise.all(promises);
    this.refreshCartPrice();
  }


  async promotionDialog() {
    const modal = await this.modalController.create({
      component: PromotionDialogComponent,
      cssClass: 'promotion-dialog'
    });
    modal.present();
    return modal.onWillDismiss();
  }

  async confirmModal() {
    const modal = await this.modalController.create({
      component: ConfirmModalComponent,
      cssClass: 'auto-height'
    });
    modal.present();
  }

  async warningBonusSelected() {
    const modal = await this.modalController.create({
      component: ConfirmModalCartComponent,
      cssClass: 'auto-height text-center popup-bonus-warning',
      componentProps: {
        bonusModal: true
      },
      backdropDismiss: false,
    });

    modal.onWillDismiss().then((resp) => {
      if (resp) {
        if (resp.data.useWallet) {
          this.coupon = null;
        }
        this.applyWallet(resp.data.useWallet);
      }
    })

    modal.present();

  }

  async popupBeforePay(freeShippingAmount, postalCode) {
    const modal = await this.modalController.create({
      component: PopupBeforePayComponent,
      cssClass: 'popup-before-pay auto-height',
      componentProps: {
        freeShippingAmount,
        postalCode
      }
    });
    modal.present();
    return modal.onWillDismiss();
  }

  async presalewarning() {
    const modal = await this.modalController.create({
      component: PresaleWarningComponent,
      cssClass: 'auto-height',
    });
    modal.present();
    return modal.onWillDismiss();
  }

  hasPromotionProduct(slug) {
    return this.cart.items.find(_item => _item.product.slug == slug);
  }


  stockAvailableForCart() {
    let stockAvailable = true;
    if (this.bindingStockExceeded.covers.length || this.bindingStockExceeded.rings.length) {
      return false;
    }
    for (const item of this.cart.items) {
      if ((item.qty > item.product.stock && !item.product.allowSaleNoStock) || item.product.stock === undefined) {
        stockAvailable = false;
        break;
      }
    }
    return stockAvailable;
  }

  refreshBindingStockExceeded() {
    this.bindingStockExceeded = {
      covers: this.cartBindingStockUses.covers.filter(cov1 => {
        // Encuentra una coincidencia exacta en el stock
        const matching = this.bindingStock.covers.find(cov2 =>
          cov1.color === cov2.color && cov1.pageSize === cov2.pageSize
        );

        // Verifica si el tamaño es A5 para considerar el stock de A4
        if (cov1.pageSize === 'A5') {
          // Encuentra el stock de A4 del mismo color
          const a4Matching = this.bindingStock.covers.find(cov2 =>
            cov1.color === cov2.color && cov2.pageSize === 'A4'
          );

          // Calcula el stock total disponible para A5, incluyendo A4 partidas por la mitad
          const availableStock = (matching?.stock || 0) + (a4Matching?.stock || 0) * 2;

          // Retorna true si el stock total es insuficiente
          return cov1.uses > availableStock;
        }

        // Si no es A5, usa la lógica original
        return matching && cov1.uses > matching.stock;
      }),
      rings: this.cartBindingStockUses.rings.filter(ring1 => {
        const matching = this.bindingStock.rings.find(ring2 =>
          ring1.color === ring2.color && ring1.diameter == ring2.diameter
        );

        return matching && ring1.uses > matching.stock;
      }),
    };
  }

  /*checkRingsStock(bindingRings) {
    //return this.bindingStockExceeded.rings.includes(bindingRings);
    if (this.currentStep < 2)
      return;
    
    return bindingRings.some(ring => 
      this.bindingStockExceeded.rings.some(exceededRing =>
        ring.color === exceededRing.color &&
        ring.diameter === exceededRing.diameter
      )
    )
  }*/

  exceededBindingItems(printingGroup) {
    const elementsExceeded = [];
    if (printingGroup.printSettings[Settings.FINISHING] !== Finishing.BINDING) {
      return elementsExceeded;
    }
    const covers = printingGroup.binding.covers.filter((cov1) => {
      return this.bindingStockExceeded.covers.find(
        (cov2) => cov1.color === cov2.color && cov1.pageSize === cov2.pageSize
      );
    });

    const rings = printingGroup.binding.rings.filter((cov1) => {
      return this.bindingStockExceeded.rings.find(
        (cov2) => cov1.color === cov2.color && cov1.diameter == cov2.diameter
      );
    });

    covers.forEach((cov) => {
      elementsExceeded.push(`Tapa ${cov.name.toLowerCase()}`);
    });

    rings.forEach((ring) => {
      elementsExceeded.push(`Anilla ${ring.name.toLowerCase()}`);
    });

    return elementsExceeded;
  }

  _API_validate_files(data = null) {
    return this.functions.httpsCallable(this.API_VALIDATE_FILES)(data).toPromise();
  }

  async checkPrintFiles() {
    let availableGroups = true;
    return new Promise<boolean>((resolve) => {
      if (this.cart.items.some(e => e.product.type === ProductType.PRINT)) {
        this._API_validate_files({ cartItems: this.cart.items }).then(res => {
          res.cartItemIndexes.forEach(index => {
            if (this.cart.items[index]) {
              this.cart.items[index] = {
                ...this.cart.items[index],
                unavailable: true
              };
              availableGroups = false;
            }
          });
          resolve(availableGroups);
        }).catch(err => {
          resolve(availableGroups);
        });
      } else {
        resolve(availableGroups);
      }
    });
  }

  hasErrors() {
    return this.cart.items
      .filter(item => item.product.type === ProductType.PRINT)
      .some(item => item.product.printingGroup.files.some(file => file.status === PrintFileStatus.FAILED));
  }

  hasPrintProtectedFiles() {
    return this.cart.items
      .filter(item => item.product.type === ProductType.PRINT)
      .some(item => item.product.printingGroup.files.some(file => file.printProtected));
  }

  normalizeFiles() {
    this.getPrintProductsInCart().forEach(it => {
      it.product.printingGroup.files.forEach((file, i) => {
        if (file.coverLaminated) {
          file.coverColor = true;
          if (file.coverType.paperWeight !== PaperWeight.W80) {
            file.coverType.paperWeight = PaperWeight.W80
          }
        } else if (file.coverColor && file.docColor) {
          file.coverColor = false;
        }
      });
    });
  }

  deleteProtectedFilesFromCart() {
    localStorage.setItem('printAllowed', this.encryptS.encrypt('false'));
    this.cart.items.forEach(it => {
      it.product.printingGroup.files.forEach((file, i) => {
        if (file.printProtected) {
          this.delPrintGroupFile(it, i);
        }
      });
    });

    this.saveCart(this.cart);
  }

  delPrintGroupFile(printGroup, i) {
    printGroup.product.printingGroup.files.splice(i, 1);
    if (!printGroup.product.printingGroup.files.length) {
      this.delPrintProduct(printGroup);
    }
    this.productService.setPrintProduct(printGroup, printGroup.product.printingGroup.files);
    this.refreshCartPrice();
    this.printProductStateService.refresh();
  }

  // private acceptProtectedFilesFromCart() {
  //   localStorage.setItem('printAllowed', this.encryptS.encrypt('true'));
  //   const filesToProcess = this.printProduct.product.printingGroup.files.filter(file => file.printProtected);
  //   const filesToChangeStatusUploaded = this.files.filter(file => filesToProcess.some(fileProc => fileProc.originalFile.name === file.file.name));
  //   filesToChangeStatusUploaded.forEach(file => {
  //     file.isUploaded = false;
  //   });
  //   filesToProcess.forEach(file => {
  //     this.fileService.processFile(file.id, this.printProduct.product.printingGroup.printSettings, 'uploads')
  //       .catch();
  //   });
  // }

  async openCartPreviewMenu() {
    if (this.hasErrors()) {
      const toast = await this.toastController.create({
        message: 'Algunos archivos tienen errores. Elimínalos antes de continuar.',
        color: 'danger',
        position: 'bottom',
        cssClass: 'fitWidthToast',
        duration: 2000
      });
      await toast.present();
      return;
    }
    if (this.currentStep < 1) {
      this.menuCtrl.open('cart-preview');
    }
  }

  async goToCart() {
    if (this.hasErrors()) {
      const toast = await this.toastController.create({
        message: 'Algunos archivos tienen errores. Elimínalos antes de continuar.',
        color: 'danger',
        position: 'bottom',
        cssClass: 'fitWidthToast',
        duration: 2000
      });
      await toast.present();
      return;
    }

    await this.router.navigate(['/cart']);
  }

  refreshPrintProductsBindingUses() {
    this.cart.items.forEach(it => {
      this.productService.setPrintProductBindingUses(it)
    });
    this.setCartBindingUses();
  }

  setCartBindingUses() {
    this.cartBindingStockUses = { covers: [], rings: [] };

    this.cart.items.forEach(it => {
      const printingGroup = it.product.printingGroup;

      if (it.product.type === ProductType.PRINT && printingGroup.printSettings[Settings.FINISHING] === Finishing.BINDING) {
        const combineObjects = (targetArray, sourceArray, keyProps) => {
          sourceArray.forEach(obj => {
            const matchingObj = targetArray.find(targetObj => {
              return keyProps.every(key => targetObj[key] === obj[key]);
            });

            if (matchingObj) {
              matchingObj.uses += obj.uses;
            } else {
              targetArray.push({ ...obj });
            }
          });
        };
        combineObjects(this.cartBindingStockUses.rings, printingGroup.binding.rings, ['color', 'diameter']);
        combineObjects(this.cartBindingStockUses.covers, printingGroup.binding.covers, ['color', 'pageSize']);
      }
    });
    this.refreshBindingStockExceeded();
  }

  checkCartTaxes(isCanary) {
    this.cart.items.forEach(item => {
      if (isCanary && !item.product.unitPrice?.amtWithTax) {
        item.product.unitPrice.amtWithTax = item.product.unitPrice.amt;
        item.product.unitPrice.amt = round2(item.product.unitPrice.amt / 1.21);
        item.price.amt = round2(item.product.unitPrice.amt * item.qty);
        this.cart.price.taxPct = 0;
      } else if (!isCanary && item.product.unitPrice?.amtWithTax) {
        item.product.unitPrice.amt = round2(item.product.unitPrice.amtWithTax);
        item.price.amt = round2(item.product.unitPrice.amt * item.qty);
        this.cart.price.taxPct = 21
        delete item.product.unitPrice.amtWithTax
      }
    });
  }

  getWalletDiscount() {
    return (this.clonnedOrder ?? this).cart.discounts.find(dis => dis.type == 'wallet')?.amount ?? 0;
  }

  async showConfirmCommentInfo(event) {
    const commentConfirmationCookie = this.cookieService.get("commentConfirmation");
    if (event?.target.disabled || !!commentConfirmationCookie) {
      return true
    }
    const modal = await this.modalController.create({
      component: ConfirmCommentModalComponent,
      cssClass: "auto-height",
      backdropDismiss: false,
    });
    modal.present();

    const res = await modal.onDidDismiss();
    if (!res.data) {
      if (event) {
        event.target.disabled = true;
        event.target.blur();
        setTimeout(() => {
          event.target.disabled = false;
        }, 300);
      }
      return false
    } else {
      this.cookieService.set('commentConfirmation', '1')
      return true
    }
  }


  public paymentResult = new Subject<void>();
  public paymentResult$ = this.paymentResult.asObservable();

  private async addMissingProperties(printingGroup: PrintingGroup) {
    const files: _PrintFile[] = printingGroup.files
    const propertiesToCheck = ['laminationType', 'laminationWeight']
    const propertiesToAdd = {
      coverLaminated: false
    }
    const printProduct = await firstValueFrom(this.productService.printProductDb$);
    const defaultSettings = printProduct.printingGroup.printSettings;
    for (const [index, file] of files.entries()) {
      for (const prop in propertiesToAdd) {
        if (!file[prop]) {
          file[prop] = propertiesToAdd[prop]
        }
      }
      for (const prop of propertiesToCheck) {
        if (!file[prop]) {
          file[prop] = !!printingGroup.printSettings[prop] ? printingGroup.printSettings[prop] : defaultSettings[prop]
        }
      }
      if (!file.coverType) {
        this.fileService.restartCoverTypes(printingGroup, index)
      }
    }
  }

  deleteClonnedOrderState() {
    this.clonnedOrder = null;
    this.walletDiscountClonnedOrder = null;
  }

  setClonnedDiscountWallet() {
    this.walletDiscountClonnedOrder = this.clonnedOrder?.cart.discounts.find(disc => disc.type === DiscountType.WALLET);
  }


}

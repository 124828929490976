import { UserService } from './../core/user.service';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { serverTimestamp, Timestamp } from '@angular/fire/firestore';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Order, PaperWeight, ProductType, UpdatedBy } from '../models/models';
import { AppService } from './app.service';
import { Product } from '../models/product.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';


/**
 * Operaciones en base de datos referente a órdenes
 */
@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private collection: AngularFirestoreCollection<Order>;
  public readonly collectionName: string = 'orders';
  public orders: Array<Order>;

  constructor(
    private db: AngularFirestore,
    private functions: AngularFireFunctions,
    private userService: UserService,
    private appService: AppService
  ) {
    this.collection = this.db.collection<Order>('orders');
  }

  ifTimestamp2Date = property => property instanceof Timestamp ? property.toDate() : property;

  get(id): Promise<Order> {
    return this.collection.doc(id)
      .get()
      .toPromise()
      .then(snap => {
        let ret: Order = null;
        if (snap.exists) {
          // const data = {...snap.data()};
          const data = this.normalizeResponse(snap.data());
          ret = { id: snap.id, ...data } as Order;
        }
        return ret;
      });
  }

  get$(id): Observable<Order> {
    return this.collection.doc<Order>(id).valueChanges()
      .pipe(
        map(order => {
          order.id = id;
          order = this.normalizeResponse(order);
          return order;
        })
      );
  }


  upsert(_data): Promise<any> {
    //console.log('order',_data);

    const { id, ...data } = _data;
    if (!id) {
      data.createdAt = serverTimestamp();
    }
    data.updatedAt = serverTimestamp();
    data.updatedBy = this.userService.userG?.id ? {
      id: this.userService.userG.id,
      displayName: this.userService.userG.displayName,
      from: UpdatedBy.From.APP
    } : {
      id: null,
      displayName: null,
      from: UpdatedBy.From.APP
    };
    return (id)
      ? this.collection.doc(id).update(data)
      : this.collection.add(data);
  }

  // devuelve un numero de order asignado por el servidor
  async getOrderNumber(): Promise<any> {
    const { number } = await firstValueFrom(this.functions.httpsCallable('api2/orders/bookNumber')({}));
    return number;
  }

  getByUserId(userId, pageSize, startAfterDoc = null) {
    let query = this.db.collection('orders', ref => {
      let queryRef = ref.where('userId', '==', userId).orderBy('createdAt', 'desc').limit(pageSize);
      if (startAfterDoc) {
        queryRef = queryRef.startAfter(startAfterDoc);
      }
      return queryRef;
    });

    return query.snapshotChanges().pipe(
      map(docs => docs.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        this.normalizeResponse(data);
        return { id, ...data };
      }))
    );
  }

  getTotalOrdersCount(userId) {
    return this.db.collection('orders', ref => ref.where('userId', '==', userId))
      .get()
      .pipe(
        map(snapshot => snapshot.size)
      );
  }

  getByUserIdThisMonth(userId) {
    const currentDate = new Date();
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    return this.db.collection('orders', ref => ref.where('userId', '==', userId)
      .where('createdAt', '>=', firstDay)
      .where('createdAt', '<=', lastDay)
      .orderBy('createdAt', 'desc').limit(50))
      .snapshotChanges()
      .pipe(
        map(docs => docs.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          this.normalizeResponse(data);
          return { id, ...data };
        }))
      );
  }

  //NEW XA
  //Traes el id para poder compartir el pedido
  // getByUserId2(userId){
  //   return this.db.collection('orders', ref => ref
  //   .where('userId','==',userId)
  //   .orderBy('createdAt', 'desc')
  //   ).get()
  //   .pipe( map( col => {
  //     let order: Array<Object> = [] as any;
  //     if(col.docs){
  //       col.docs.forEach(r=>{
  //         const id = r.id;
  //         let data = r.data() as Order;
  //         data = this.normalizeResponse(data);
  //         order.push({id,...data})
  //       })
  //     }
  //     return order;
  //   }))
  // }

  normalizeResponse(data) {
    data.createdAt = this.ifTimestamp2Date(data.createdAt);
    data.cart.createdAt = this.ifTimestamp2Date(data.cart.createdAt);
    data.cart.updatedAt = this.ifTimestamp2Date(data.cart.updatedAt);
    data = this.back2Present(data);
    return data;
  }

  back2Present(order: Order) {
    order.cart.items.map(item => {
      if (item.product.type === ProductType.PRINT) {
        item.product.printingGroup.printSettings.paperWeight = item.product.printingGroup.printSettings.paperWeight ?? PaperWeight.W80;
      }
      this.appService.upgradePrintProduct(item.product as Product);
      return item;
    });
    return order;
  }

}

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { LaminationType, LaminationWeight, Settings } from "../../models/models";
import { CommonModule } from "@angular/common";
import { IonicModule, ModalController } from "@ionic/angular";
import { FileService } from "../../shared/file.service";
import { PrintProductStateService } from "../../shared/print-product-state.service";
import { Subscription } from "rxjs";
import {
  TooltipInfoLaminationWeightComponent
} from "../tooltip-info-components/tooltip-info-lamination-weight/tooltip-info-lamination-weight.component";
import { AppService } from "../../shared/app.service";

@Component({
  selector: 'app-lamination-options',
  templateUrl: './lamination-options.component.html',
  styleUrls: ['./lamination-options.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    TooltipInfoLaminationWeightComponent,
  ],
  standalone: true
})
export class LaminationOptionsComponent implements OnInit, AfterViewInit {
  @ViewChildren('videoElement') videos!: QueryList<ElementRef<HTMLVideoElement>>;

  @Input() file;
  @Input() showButtons;
  @Input() settings;
  @Output() settingSelectedEvent = new EventEmitter<any>();
  @Output() optionSelect = new EventEmitter<any>();
  @Output() finalValues = new EventEmitter<any>();
  @Input() laminationWeight: LaminationWeight;
  @Input() laminationType: LaminationType;
  @Input() inFile = false;
  @Input() styleType: 'default' | 'new' = 'default';
  printProductSubscription: Subscription;
  coverLaminated;

  constructor(public fileService: FileService, private modalController: ModalController, private printProductStateService: PrintProductStateService,
              private appService: AppService) {
  }

  ngOnInit() {
    this.laminationWeight = !this.file ? this.settings[Settings.LAMINATION_WEIGHT].options[this.settings[Settings.LAMINATION_WEIGHT].selected - 1].value
      : this.file.laminationWeight
    this.laminationType = !this.file ? this.settings[Settings.LAMINATION_TYPE].options[this.settings[Settings.LAMINATION_TYPE].selected - 1].value
      : this.file.laminationType
    this.printProductSubscription = this.printProductStateService.printProduct$
      .subscribe((printProduct) => {
        this.coverLaminated = printProduct.product.printingGroup.printSettings.coverLaminated
      });
  }

  ngAfterViewInit() {
    this.videos.forEach((video: ElementRef<HTMLVideoElement>) => {
      this.addHoverEvent(video.nativeElement);
    });
  }

  ngOnDestroy() {
    this.printProductSubscription.unsubscribe();
  }

  private addHoverEvent(videoElement: HTMLVideoElement) {
    videoElement.addEventListener('mouseenter', () => {
      videoElement.play();
    });

    videoElement.addEventListener('mouseleave', () => {
      videoElement.pause();
      videoElement.currentTime = 0;
    });
  }

  selectOption(i, settingOption) {
    const prevWeight = this.settings[Settings.LAMINATION_WEIGHT].selected;
    const prevType = this.settings[Settings.LAMINATION_TYPE].selected;

    this[settingOption] = this.settings[settingOption].options[i].value;

    if (!this.file) {
      const weightIndex = this.settings[Settings.LAMINATION_WEIGHT].options.findIndex(el => el.value === this.laminationWeight);
      this.settings[Settings.LAMINATION_WEIGHT].selected = weightIndex + 1;

      const typeIndex = this.settings[Settings.LAMINATION_TYPE].options.findIndex(el => el.value === this.laminationType);
      this.settings[Settings.LAMINATION_TYPE].selected = typeIndex + 1;

      this.optionSelect.emit({
        setting: Settings.LAMINATION_WEIGHT,
        option: this.settings[Settings.LAMINATION_WEIGHT].selected,
        prev: prevWeight
      });

      this.optionSelect.emit({
        setting: Settings.LAMINATION_TYPE,
        option: this.settings[Settings.LAMINATION_TYPE].selected,
        prev: prevType
      });
    }

    this.finalValues.emit({
      laminationType: this.laminationType,
      laminationWeight: this.laminationWeight
    });
  }

  isDisabled(i, settingOption) {
    const isAvailable = this.checkAvailability(i, settingOption);
    if (!isAvailable && this[settingOption] === this.settings[settingOption].options[i].value) {
      this.selectAnother(settingOption);
    }
    return !isAvailable;
  }

  selectAnother(settingOption) {
    const available = this.settings[settingOption].options.findIndex((ele, index) => this.checkAvailability(index, settingOption));
    const setting = this.settings[settingOption];
    setTimeout(() => {
      setting.selected = available + 1;
      this[settingOption] = setting.options[available].value;
      if (this.inFile) {
        this.file[settingOption] = setting.options[available].value;
      }
      if (!this.inFile) {
        this.optionSelect.emit({
          setting: settingOption,
          option: setting.selected,
          prev: this.settings[settingOption].selected
        });

        this.finalValues.emit({
          laminationType: this.laminationType,
          laminationWeight: this.laminationWeight
        });
      }
    }, 10);
  }

  checkAvailability(i, settingOption) {
    const option = this.settings[settingOption].options[i];

    const unavailableFor = option.unavailableFor || [];
    const unavailableForGrouped = option.unavailableForGrouped || [];

    const isUnavailable = unavailableFor.some(unavailable => {
        const optionSelected = !!this.file && this[unavailable.id] ? this.settings[unavailable.id].options.findIndex(option => option.value === this[unavailable.id]) + 1
          : this.settings[unavailable.id].selected;
        return unavailable.options.includes(optionSelected)
      }
    );

    const isUnavailableGrouped = unavailableForGrouped.some(group => {
        return group.properties.every(unavailable => {
          const optionSelected = !!this.file && this[unavailable.id] ? this.settings[unavailable.id].options.findIndex(option => option.value === this[unavailable.id]) + 1
            : this.settings[unavailable.id].selected;
          return unavailable.options.includes(optionSelected)
        });
      }
    );

    return !isUnavailable && !isUnavailableGrouped;
  }

  closeModal() {
    this.modalController.dismiss()
  }

  submit() {
    this.modalController.dismiss({ laminationWeight: this.laminationWeight, laminationType: this.laminationType })
  }

  protected readonly Settings = Settings;

  changeDocLaminationType(event: any) {
    this.file.laminationType = event.detail.value;
    this.laminationType = event.detail.value;

    // Comprobar si el laminationWeight actual es válido para el nuevo laminationType
    const weightIndex = this.settings[Settings.LAMINATION_WEIGHT].options.findIndex(option => option.value === this.laminationWeight);
    const isWeightAvailable = this.checkAvailability(weightIndex, Settings.LAMINATION_WEIGHT);

    // Si el laminationWeight actual no es válido, seleccionar otro
    if (!isWeightAvailable) {
      this.selectAnother(Settings.LAMINATION_WEIGHT);
    }

    // Emitir los valores finales después de asegurarse de que laminationType y laminationWeight son válidos
    setTimeout(() => {
      this.finalValues.emit({
        laminationType: this.laminationType,
        laminationWeight: this.laminationWeight
      });
    }, 300);
  }

  changeDocLaminationWeight(event: any) {
    this.file.laminationWeight = event.detail.value
    this.laminationWeight = event.detail.value
    setTimeout(() => {
      this.finalValues.emit({
        laminationType: this.laminationType,
        laminationWeight: this.laminationWeight
      });
    }, 300)
  }
}

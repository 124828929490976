import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthBody, AuthOutput, PreauthBody, PreauthOutput } from './redsys-insite.types';

@Injectable()
export class RedsysPaymentTransactionService{

  getTransaction$ = (id):Observable<any> => {
    return of(null);
  }
  preAuth = async (preauthBody:PreauthBody):Promise <PreauthOutput> =>{
    return Promise.resolve(null);
  }
  auth = async (authBody:AuthBody):Promise <AuthOutput> =>{
    return Promise.resolve(null);
  }
}

import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { environment } from '../environments/environment';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';

import { HttpClientModule } from '@angular/common/http';
// import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ComponentsModule } from './components/components.module';
import { AnalyticsService } from './shared/analytics.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ShippingService } from './shared/services/shipping.service';
import { AddDocumentPopupModule } from './creators/add-document-popup/add-document-popup.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorIntlService } from './shared/custom-paginator';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { FormsModule } from '@angular/forms';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SharedLibModule } from 'shared-lib';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { ElasticSearchService } from './core/elastic-search.service';
// import { SETTINGS as AUTH_SETTINGS } from '@angular/fire/compat/auth';
import { SharedComponentsModule } from './cart/shared-components.module';
import { PaymentTransactionService } from './core/payment-transaction.service';
import { RedsysPaymentTransactionService } from './shared/redsys-insite/redsys-payment-transaction.service';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getAuth, provideAuth } from '@angular/fire/auth';

registerLocaleData(localeEs, 'es-ES');
// import { ElasticSearchService } from '@a2system/angular/common';


// export let options: Partial | (() => Partial);
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    //CartPreviewComponent,
    //ItemShopProductComponent,
  ],
  imports: [
    SharedComponentsModule,
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
      innerHTMLTemplatesEnabled: true
    }),
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    // provideFirestore(() => getFirestore()),

    // provideAuth(() => getAuth()),
    // provideStorage(() => getStorage()),
    // provideAnalytics(() => getAnalytics()),
    // provideFunctions(() => getFunctions()),

    BrowserAnimationsModule,
    ComponentsModule,
    FlexLayoutModule,
    AddDocumentPopupModule,
    SharedLibModule,
    MaintenanceModule,
    BrowserModule,
    FormsModule,
    NgxFileDropModule

  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions(getApp(),'europe-west1')),
    provideAuth(() => getAuth()),
    // { provide: AUTH_SETTINGS, useValue: { appVerificationDisabledForTesting: true } },

    // {provide: ErrorHandler, useClass: RollbarErrorHandler},
    // {provide: RollbarService, useFactory: rollbarFactory},
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: REGION, useValue: 'europe-west1' },

    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: 'FIREBASE_REGION', useValue: 'europe-west1' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: 'googleTagManagerId', useValue: 'GTM-NJV2JD5' },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    {
      provide: MatPaginatorIntl,
      useFactory: () => {
        const service = new PaginatorIntlService();
        service.injectTranslateService();
        return service;
      }
    },
    // { provide: ORIGIN, useValue: 'http://124c45f0.ngrok.io' },
    ScreenTrackingService,
    UserTrackingService,
    AnalyticsService,
    ElasticSearchService,
    {
      provide: APP_INITIALIZER,
      useFactory: (es: ElasticSearchService) => () => es.setEsConfig(environment),
      multi: true,
      deps: [ElasticSearchService]
    },
    { provide: RedsysPaymentTransactionService, useClass: PaymentTransactionService},
    ShippingService
  ],
  bootstrap: [AppComponent],

})
export class AppModule {
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ShippingSettings, ShippingType } from 'src/app/models/shipping-settings.model';
import { SettingService } from '../setting.service';
import { PickupLocationService } from "../pickup-location.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { map } from "rxjs/operators";
import { addDays, format, isWeekend, startOfDay } from "date-fns";
import { es } from 'date-fns/locale';
import { Timestamp } from "@angular/fire/firestore";


@Injectable({
  providedIn: 'root'
})
export class ShippingService {
  private isInit = new BehaviorSubject<boolean>(false);
  public isInit$ = this.isInit.asObservable();
  freeWeightLimit;
  weightPrices;
  shippingPriceRanges;
  deliveryDaysRanges
  zoneWeightPrices;
  zoneShippingInfo;
  zoneFreeCases;
  shippingSettings: ShippingSettings;

  shippingBalancerZones = null

  hourToChange = 14
  minuteToChange = 0

  constructor(
    private settingService: SettingService,
    private pickupLocationService: PickupLocationService,
    private db: AngularFirestore,
  ) {
    this.settingService.getShippingSettings()
      .then(shippingSettings => {
        this.shippingSettings = shippingSettings;
        this.freeWeightLimit = shippingSettings.freeWeightLimit;
        this.shippingPriceRanges = shippingSettings.priceRanges;
        this.weightPrices = shippingSettings.weightPrices;
        this.deliveryDaysRanges = shippingSettings.deliveryDaysRanges;
        this.zoneWeightPrices = shippingSettings.zoneWeightPrices;
        this.zoneShippingInfo = shippingSettings.zoneShippingInfo;
        this.zoneFreeCases = shippingSettings.zoneFreeCases;
        this.isInit.next(true);
      });
    this.db.collection('settings').doc('shippingSettings').collection('shippingBalancerByZone').snapshotChanges()
      .subscribe(zones => {
        this.shippingBalancerZones = zones.map(zone => {
          const data = zone.payload.doc.data();
          const id = zone.payload.doc.id;
          return {
            id,
            service: data.service,
            postalCodePrefixesForZone: data.postalCodePrefixesForZone
          };
        });
      });
    this.db.collection<any>('settings').doc('shippingSettings').valueChanges().subscribe(settings => {
      this.deliveryDaysRanges = settings?.deliveryDaysRanges;
    });
  }


  getFreeShippingPrice(_weight, postalcode) {
    const weight = _weight / 1000;
    const cp = postalcode ? postalcode.substring(0, 2) : null;
    let weightPrices = this.getWeightPrice(cp);

    const dif = weight - this.freeWeightLimit;

    return dif > 0
      ? weightPrices.extra * Math.ceil(dif)
      : 0;
  }

  getWeightShippingPrice(_weight, postalcode) {
    const cp = postalcode ? postalcode.substring(0, 2) : null;
    let weightPrices = this.getWeightPrice(cp);
    //Pasamos el peso a kilos para  calcular el precio
    const weight = _weight / 1000;
    if (!weight) {
      return 0;
    }
    const range = weightPrices.range.find(_range => {
      return weight > _range[0] && weight <= _range[1];
    });
    if (range !== undefined) {
      return range[2];
    } else {
      const maxR = weightPrices.range[weightPrices.range.length - 1];
      //Retornamo el precio máximo + la diferencia en kilos por el precio extra por kilos
      return weightPrices.extra * Math.ceil((weight - maxR[1])) + maxR[2];
    }
  }

  /**
   * Get the shipping price for a given cart price
   * @param cartPrice
   */
  getRangeShippingPrice(cartPrice) {
    let priceRange = this.shippingPriceRanges.find(range => {
      return cartPrice >= range.minLimit && cartPrice <= range.maxLimit;
    });
    if (!priceRange) {
      const roundedValue = Math.round((cartPrice + Number.EPSILON) * 100) / 100;
      priceRange = this.shippingPriceRanges.find(range => roundedValue >= range.minLimit && roundedValue <= range.maxLimit);
    }
    return priceRange ? priceRange.price : this.shippingPriceRanges[0].price;
  }

  /**
   * Get the weight price for a given postal code
   * @param cp
   */
  getWeightPrice(cp) {
    return this.zoneWeightPrices.find(zwp => zwp.zone.includes(cp)) ?? this.weightPrices;
  }


  shippingInfo(postalcode) {
    var info = null;
    for (let caseZone of this.zoneShippingInfo) {
      if (caseZone.zone.length == 0 || this.isMatchZone(postalcode, caseZone.zone)) {
        info = caseZone.info;
        break;
      }
    }
    return info;
  }

  /**
   * Get the free shipping amount for a given postal code
   * @param postalcode
   */
  freeShipping(postalcode) {
    var amount = 0; // 0 means: no free shipping
    // We need to know if there is freeShipping already in the CartPreview, where there isn't any postalcode provided yet. So
    // it is set a default value of 35 until a postalcode value exists.
    /*if (!postalcode) {
      return 35;
    }*/
    for (let caseZone of this.zoneFreeCases) {
      if (caseZone.zone.length == 0 || this.isMatchZone(postalcode, caseZone.zone)) {
        amount = caseZone.amount;
        break;
      }
    }
    return amount;
  }

  isMatchZone(postalcode, zone) {
    var res = false;
    for (let zc of zone) {
      if (postalcode?.startsWith(zc)) {
        res = true;
        break;
      }
    }
    return res;
  }

  /**
   * Check if a shipping type is available for a given shipping address and show a message if not
   * @param shippingType shipping type to check
   * @param shippingAddress shipping address to check
   * @param cartService cart service to check if the cart has only wallet bonus
   * @param maxPrice max price to check if the shipping product price is lower than
   */
  checkShippingTypeAvailability(shippingType: ShippingType, shippingAddress, cartService, maxPrice = null): {
    available: boolean,
    message: string
  } {
    const { postalCode, pickupPointCode, shippingCourier } = shippingAddress;
    let { onlyWalletBonus, shippingProductPrice } = cartService;
    let available = shippingType === ShippingType.STANDARD ||
      (this.shippingSettings.available.includes(shippingType) &&
        !this.shippingTypeRestrictedForProvince(shippingType, postalCode, 'hidden') &&
        !this.shippingTypeRestrictedForProvince(shippingType, postalCode, 'disabled') &&
        !this.shippingTypeDisabledForPoint(shippingType, shippingAddress) &&
        this.shippingTypeAvailableInBalancer(shippingType, shippingAddress) &&
        (!maxPrice || shippingProductPrice <= maxPrice) &&
        !onlyWalletBonus);


    let message = '';
    if (!available) {
      message = 'Tipo de envío no disponible';
      if (this.shippingTypeDisabledForPoint(shippingType, shippingAddress)) {
        message = 'Tipo de envío no disponible en punto de recogida';
      } else if (this.shippingTypeRestrictedForProvince(shippingType, postalCode, 'disabled')) {
        message = 'Tipo de envío no disponible para esta provincia';
      } else if (!!maxPrice && shippingProductPrice > maxPrice) {
        message = `Tipo de envío no disponible para pedidos superiores a ${maxPrice}€`;
      } else {
        message = 'Tipo de envío no disponible';
      }
    }

    return { available, message };
  }


  shippingTypeDisabledForPoint(shippingType, shippingAddress) {
    const { addressLine1, pickupPointCode, shippingCourier } = shippingAddress;
    const availableCouriersForPoints = this.pickupLocationService.availableCouriers;
    // return point is disabled when a
    return (
      !!pickupPointCode && (
        !availableCouriersForPoints.hasOwnProperty(shippingCourier) ||
        !availableCouriersForPoints[shippingCourier].enabled ||
        (shippingType !== ShippingType.STANDARD && !availableCouriersForPoints[shippingCourier]?.shippingTypes[shippingType]))
    );
  }

  /**
   * Check if a shipping type is restricted for a given province
   * @param shippingType
   * @param postalCode
   * @param restrictionType disabled or hidden
   */
  shippingTypeRestrictedForProvince(shippingType, postalCode, restrictionType: 'hidden' | 'disabled') {
    const provinceId = postalCode ? postalCode.substring(0, 2) : null;
    const shippingRestrictions = this.shippingSettings.shippingTypeRestrictions[shippingType];
    return shippingRestrictions && shippingRestrictions.some(r => r.provinceIds.includes(provinceId) && r.show === (restrictionType === 'disabled'));
  }

  /**
   * Check if a shipping type is available in the balancer for a given shipping address
   * @param shippingType
   * @param shippingAddress
   */
  shippingTypeAvailableInBalancer(shippingType: ShippingType, shippingAddress) {
    const { postalCode } = shippingAddress;
    let currentZone;
    for (let i = 5; i > 0; i--) {
      const prefix = postalCode.substring(0, i);
      currentZone = this.shippingBalancerZones.find(zone => zone.postalCodePrefixesForZone.includes(prefix));
      if (currentZone) break;
    }
    if (!currentZone) {
      currentZone = this.shippingBalancerZones.find(zone => zone.id === 'remaining');
    }
    return Object.values(currentZone.service).some(courier => !!courier[shippingType]);
  }

  getHolidays(year: number): Observable<{ date: Timestamp; postalCodes: string[] }[]> {
    return this.db.collection('holidays').doc(`${year}`).valueChanges().pipe(
      map((data: any) => data?.holidays ?? [])
    );
  }

  /**
   * Get the delivery date for a given shipping type and shipping address
   * @param shippingType
   * @param shippingAddress
   */
  getShippingDeliveryDate(shippingType: ShippingType, shippingAddress = null): Observable<{
    minDeliveryDate: Date,
    maxDeliveryDate: Date,
    textToShow: string,
    minDays: number,
    maxDays: number
  }> {
    const now = new Date();

    // Obtener los festivos del año actual y del siguiente desde Firestore
    return combineLatest([
      this.getHolidays(now.getFullYear()),
      this.getHolidays(now.getFullYear() + 1)
    ]).pipe(
      map(([currentYearHolidays, nextYearHolidays]: [{ date: Timestamp; postalCodes: string[] }[], {
        date: Timestamp;
        postalCodes: string[]
      }[]]) => {
        const holidays = [...currentYearHolidays, ...nextYearHolidays]; // Combinar festivos de ambos años
        const currentHour = now.getHours();
        const currentMinutes = now.getMinutes();
        const limitHourPassed = currentHour > this.hourToChange || (currentHour === this.hourToChange && currentMinutes >= this.minuteToChange);
        let result = '';

        let minDays = this.deliveryDaysRanges.shippingTypes[shippingType].minDays;
        let maxDays = this.deliveryDaysRanges.shippingTypes[shippingType].maxDays;
        let minDeliveryDate = now;
        let maxDeliveryDate = now;

        if (this.isHolidayOrWeekend(now, holidays, '04') || limitHourPassed) {
          minDeliveryDate = addDays(minDeliveryDate, 1);
          maxDeliveryDate = addDays(maxDeliveryDate, 1);
        }

        while (this.isHolidayOrWeekend(minDeliveryDate, holidays, '04')) {
          minDeliveryDate = addDays(minDeliveryDate, 1);
          maxDeliveryDate = addDays(maxDeliveryDate, 1);
        }

        for (let exception of this.deliveryDaysRanges.exceptions) {
          if (exception.type === 'postalCodePrefix') {
            if (exception.value.some(prefix => shippingAddress.postalCode.startsWith(prefix))) {
              minDays += exception.days;
              maxDays += exception.days;
              continue;
            }
          }
          if (exception.type === 'shippingCourier') {
            if (exception.value.some(courier => courier === shippingAddress.shippingCourier)) {
              minDays += exception.days;
              maxDays += exception.days;
            }
          }
        }

        const minDaysAux = minDays;
        const maxDaysAux = maxDays;

        // Calcular la fecha mínima y máxima de entrega

        while (this.isHolidayOrWeekend(minDeliveryDate, holidays, shippingAddress.provinceId) || minDays > 0) {
          minDeliveryDate = addDays(minDeliveryDate, 1);
          if (minDays > 0 && !this.isHolidayOrWeekend(minDeliveryDate, holidays, shippingAddress.provinceId)) {
            minDays--
          }
        }

        while (this.isHolidayOrWeekend(maxDeliveryDate, holidays, shippingAddress.provinceId) || maxDays > 0) {
          maxDeliveryDate = addDays(maxDeliveryDate, 1);
          if (maxDays > 0 && !this.isHolidayOrWeekend(maxDeliveryDate, holidays, shippingAddress.provinceId)) {
            maxDays--
          }
        }
        // Generar el mensaje en función del tipo de envío
        if (minDeliveryDate.getTime() === maxDeliveryDate.getTime()) {
          result = `Llega `;
          if (shippingType === ShippingType.STANDARD_24) {
            result += 'a lo largo del día, '
          }
          if (shippingType === ShippingType.URGENT) {
            result += 'antes de las 15:00, '
          }
          result += `${format(minDeliveryDate, 'EEEE\', \' dd/MM', { locale: es })}`;
        } else {
          result = `Entrega prevista: ${format(minDeliveryDate, 'EEEE\', \' dd/MM', { locale: es })} - ${format(maxDeliveryDate, 'EEEE\', \' dd/MM', { locale: es })}`;
        }

        minDeliveryDate.setHours(7, 0, 0)
        shippingType === ShippingType.URGENT ? maxDeliveryDate.setHours(15, 0, 0) : maxDeliveryDate.setHours(23, 59, 0)
        return {
          minDeliveryDate, maxDeliveryDate, textToShow: result, minDays: minDaysAux, maxDays: maxDaysAux
        }
      })
    );
  }

  private isHolidayOrWeekend(date: Date, holidays: {
    date: Timestamp;
    postalCodes: string[]
  }[], provinceId): boolean {
    // Verificar si el día es fin de semana
    const isWeekendDay = isWeekend(date);
    // Verificar si el día es festivo
    const isHoliday = holidays.some(holiday => {
      const isSameDate = startOfDay(holiday.date.toDate()).getTime() === startOfDay(date).getTime();

      const isPostalCodeMatch = holiday.postalCodes
        ? holiday.postalCodes.includes(provinceId)
        : true;
      return isSameDate && isPostalCodeMatch;
    });

    return isWeekendDay || isHoliday;
  }

}

function objToArr(range: any) {
  throw new Error('Function not implemented.');
}


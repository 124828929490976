import { animate, sequence, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductList } from 'src/app/models/product-ui.model';
import { ProductHandle } from 'src/app/shop/productos/handler/product-handler';

@Component({
  selector: 'app-product-card-info',
  templateUrl: './product-card-info.component.html',
  styleUrls: ['./product-card-info.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0 }), // Comienza completamente oculto
        animate('200ms ease-out', style({ opacity: 1 })) // Se desvanece en
      ]),
      transition(':leave', [
        animate('500ms ease-in', style({ opacity: 0 })) // Se desvanece fuera
      ])
    ])
  ]
})
export default class ProductCardInfoComponent extends ProductHandle implements OnInit, OnChanges {

  @Input() product: ProductList;
  @Input() hover = false;
  @Input() smallLabels = false;
  @Input() openCartSteps : boolean = false;
  @Input() addedToCartConfirmation: boolean = false;
  @Input() qty;
  @Input() productObservable$: Observable<any>;
  price;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.productObservable$?.subscribe(product => {
      //console.log('Producto en suscripción:', product);
      this.price = product.price;
    });
  }

  ngOnChanges() {
    
  }
}

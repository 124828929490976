import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/compat/firestore';
import { Wallet, WalletTransaction } from './wallet.models';
import { map, take } from 'rxjs/operators';
import { Timestamp } from '@angular/fire/firestore';

import { Observable, Subject } from 'rxjs';
import { UserService } from '../core/user.service';
import { cloneDeep } from "lodash";


@Injectable({
  providedIn: 'root'
})
export class WalletService {
  private collection: AngularFirestoreCollection<Wallet>;
  wallet: Wallet;
  applyWallet$ = new Subject<void | boolean>();

  constructor(
    private db: AngularFirestore,
    private userService: UserService
  ) {
    this.wallet = this.cleanedWallet();
    this.collection = this.db.collection<Wallet>('wallets');
    this.userService.user$.subscribe(user => {
        if (user?.id) {
          this.get$(user.id).subscribe(wallet => {
            this.wallet = wallet;
            this.applyWallet$.next();
          });
        } else {
          this.wallet = this.cleanedWallet()
        }
      }
    );
  }

  get$(id): Observable<Wallet> {
    return this.collection.doc<Wallet>(id).valueChanges()
      .pipe(
        map(doc => {
          // console.log("Lllego", doc);
          if (doc) {
            let createdAt = doc.createdAt as any as Timestamp;
            doc.createdAt = createdAt.toDate();

          } else {
            doc = this.cleanedWallet();
            // console.log('No llego, this.cleanedWallet(): ', doc);
          }
          return doc;
        })
      );
  }

  getTransaction$(id): Observable<WalletTransaction[]> {
    return this.collection.doc(id)
      .collection<WalletTransaction>("wallettransactions", ref => ref.orderBy('createdAt', 'desc'))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as any;
          ;
          data.createdAt = data.createdAt.toDate();
          const id = a.payload.doc.id;
          return { id, ...data } as WalletTransaction;
        }))
      )

  }

  cleanedWallet() {
    return {
      createdAt: null,
      updatedAt: null,
      userId: null,
      balance: 0,
      currency: 'EUR'
    }
  }
}


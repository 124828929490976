import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { firstValueFrom, Observable, take } from 'rxjs';
import { AuthBody, AuthOutput, CheckoutBody, CheckoutOutput, PreauthBody, PreauthOutput, RedirectBody, RedirectOutput } from '../shared/redsys-insite/redsys-insite.types';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { RedsysPaymentTransactionService } from '../shared/redsys-insite/redsys-payment-transaction.service';
import { TransactionModel } from '../models/transactions.model';

export const TRANSACTION_COLLECTION = 'transactions';

@Injectable({
  providedIn: 'root'
})
export class PaymentTransactionService implements RedsysPaymentTransactionService{
  http = inject(HttpClient);
  db = inject(Firestore);
  functions = inject(Functions)
  constructor() {}

  getDoc = (collection,id) => {
    return firstValueFrom(this.getDoc$(collection,id).pipe(take(1)));
  }

  getDoc$ = (collection,id):Observable<any> => {
    const docRef = doc(this.db, `${collection}/${id}`);
    const ret = docData(docRef);
    return ret;
  }

  getTransaction$ = (id):Observable<TransactionModel> => {
    return this.getDoc$(TRANSACTION_COLLECTION, id) as Observable<TransactionModel>;
  }

  checkout = (checkoutParams:CheckoutBody):Promise <CheckoutOutput> => {
    const checkoutResponse = httpsCallableData(this.functions,'api2/payments/checkout')(checkoutParams);
    return firstValueFrom(checkoutResponse) as Promise <CheckoutOutput>;
  }

  redirect = (redirectParams:RedirectBody):Promise <RedirectOutput> => {
    const response = httpsCallableData(this.functions,'api2/payments/redirect')(redirectParams);
    return firstValueFrom(response) as Promise <RedirectOutput>;
  }

  preAuth = async (preauthBody:PreauthBody):Promise <PreauthOutput> =>{
    //console.log('preauthBody', preauthBody);
    const preauthResponse = firstValueFrom(httpsCallableData(this.functions,'api2/payments/preauth')(preauthBody));
    return preauthResponse as Promise <PreauthOutput>;
  }

  auth = async (authBody:AuthBody):Promise <AuthOutput> =>{
    const authResponse = firstValueFrom(httpsCallableData(this.functions,'api2/payments/auth')(authBody));
    return authResponse as Promise <AuthOutput>;
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-icon {
  height: 100%;
  padding: 5px;
  color: black;
}
ion-icon.red {
  color: #EB445A !important;
}

ion-button {
  min-height: 0;
  height: 32px;
}
ion-button::part(native) {
  margin: 0 !important;
  padding: 0 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNsb3NlLW1vZGFsLWJ1dHRvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7RUFDQSxZQUFBO0VBQ0EsWUFBQTtBQUNGO0FBQ0U7RUFDRSx5QkFBQTtBQUNKOztBQUdBO0VBQ0UsYUFBQTtFQUtBLFlBQUE7QUFKRjtBQUFFO0VBQ0Usb0JBQUE7RUFDQSxxQkFBQTtBQUVKIiwiZmlsZSI6ImNsb3NlLW1vZGFsLWJ1dHRvbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImlvbi1pY29uIHtcbiAgaGVpZ2h0OiAxMDAlO1xuICBwYWRkaW5nOiA1cHg7XG4gIGNvbG9yOiBibGFjaztcblxuICAmLnJlZCB7XG4gICAgY29sb3I6ICNFQjQ0NUEgIWltcG9ydGFudDtcbiAgfVxufVxuXG5pb24tYnV0dG9uIHtcbiAgbWluLWhlaWdodDogMDtcbiAgJjo6cGFydChuYXRpdmUpIHtcbiAgICBtYXJnaW46IDAgIWltcG9ydGFudDtcbiAgICBwYWRkaW5nOiAwICFpbXBvcnRhbnQ7XG4gIH1cbiAgaGVpZ2h0OiAzMnB4Oztcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/close-modal-button/close-modal-button.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,YAAA;AACF;AACE;EACE,yBAAA;AACJ;;AAGA;EACE,aAAA;EAKA,YAAA;AAJF;AAAE;EACE,oBAAA;EACA,qBAAA;AAEJ;AACA,ovBAAovB","sourcesContent":["ion-icon {\n  height: 100%;\n  padding: 5px;\n  color: black;\n\n  &.red {\n    color: #EB445A !important;\n  }\n}\n\nion-button {\n  min-height: 0;\n  &::part(native) {\n    margin: 0 !important;\n    padding: 0 !important;\n  }\n  height: 32px;;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

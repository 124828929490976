import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, map, take } from 'rxjs';
import { Product } from 'src/app/models/product.model';
import { ProductBlService } from 'src/app/shared/services/productBl.service';

@Component({
  selector: 'app-products-in-pack',
  templateUrl: './products-in-pack.component.html',
  styleUrls: ['./products-in-pack.component.scss'],
})
export class ProductsInPackComponent implements OnInit {

  @Input() packProducts : Array<any>;

  products: Array<Product> = [];

  constructor(
    public productBlService: ProductBlService,
  ) { }

  ngOnInit() {
    console.log('PackProducts received', this.packProducts);

    const observables = this.packProducts.map(product => {
      if (product.variationId) {
        return this.productBlService.productWithVariants$(product.productId).pipe(
          map(prod => {
            const variationId = product.variationId;
            if (!prod) return null;

            let variation = variationId ? prod.variants.find(variant => variant?.id === variationId) : prod.variants.length ? prod.variants[0] : null;

            if (variation) {
              variation.title = prod.title;
            }

            return variation;
          })
        );
      } else {
        return this.productBlService.product$(product.productId);
      }
    });

    combineLatest(observables).subscribe(results => {
      this.products = results.filter(result => result !== null);
    })

  }

  getQty(product) {
    return this.packProducts.find(prod => (prod.variationId ?? prod.productId) === product.id).qty;
  }

}

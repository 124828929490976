import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AlertController, ToastController } from "@ionic/angular";
import { CartService } from "../shared/cart.service";

@Injectable({
  providedIn: 'root'
})
export class CanLeaveGuard  {

  constructor(private alertController: AlertController, private cartService: CartService, private toastController: ToastController) {

  }

  async canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Promise<boolean> {
    if (component.isLoading) {
      const alert = await this.alertController.create({
        header: 'Espera a que todos los archivos terminen de procesarse',
        backdropDismiss: false,
        buttons: [
          {
            text: 'De acuerdo',
            role: 'cancel',
            handler: () => {
              return false;
            }
          }
        ]
      });

      await alert.present();
      return false;
    } else if (this.cartService.hasErrors()) {
      const toast = await this.toastController.create({
        message: 'Algunos archivos tienen errores. Elimínalos antes de continuar.',
        color: 'danger',
        position: 'bottom',
        cssClass: 'fitWidthToast',
        duration: 2000
      });
      await toast.present();
      return false;
    } else {
      return true;
    }
  }

}

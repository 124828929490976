import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from "@ionic/angular";
import { OutputMessage } from 'src/app/models/common/output.model';
import { Order, OrderPaymentStatus, OrderProcessStatus, OrderTypeStatus } from 'src/app/models/models';
import { WalletService } from "../../wallet/wallet.service";

@Component({
  selector: 'app-order-actions-menu',
  templateUrl: './order-actions-menu.component.html',
  styleUrls: ['./order-actions-menu.component.scss'],
})
export class OrderActionsMenuComponent {

  @Input() isDraft: boolean;
  @Input() order: Order;
  @Output() menuAction = new EventEmitter<OutputMessage>();

  paymentStatus: typeof OrderPaymentStatus = OrderPaymentStatus;

  constructor(private popoverCtrl: PopoverController, private walletService: WalletService) {
  }

  public emitAction(type: string, value?: any): void {
    this.popoverCtrl.dismiss({ type: type, value: value });
  }

  get isPaid() {
    return this.order.paymentStatus == OrderPaymentStatus.PAID
  }

  get isRefunded() {
    return this.order.paymentStatus == OrderPaymentStatus.REFUNDED
  }

  get isProcessPending() {
    return this.order.processStatus == OrderProcessStatus.PENDING
  }

  get isProcessShipped() {
    return this.order.processStatus == OrderProcessStatus.SHIPPED
  }

  get alloworderDataModification() {
    return [OrderProcessStatus.PENDING, OrderProcessStatus.PROCESSING].includes(this.order.processStatus);
  }

  get hasReportIncident() {
    return this.order?.hasReportIncident === true;
  }

  get pendingRestartedOrderExists() {
    return this.walletService.wallet.accumulatedDiscount > 0 || this.walletService.wallet.forcedToUse
  }

}

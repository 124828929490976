import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Product } from "../../models/product.model";
import { SettingService } from "../../shared/setting.service";
import { PrintProductStateService } from "../../shared/print-product-state.service";
import { CartService } from "../../shared/cart.service";
import { Finishing, PageSize, ProductType, Settings } from "../../models/models";
import { KeyValue } from "@angular/common";
import { cloneDeep } from "lodash";

@Component({
  selector: 'app-binding-options',
  templateUrl: './binding-options.component.html',
  styleUrls: ['./binding-options.component.scss']
})
export class BindingOptionsComponent implements OnInit, OnChanges {
  @Input() additionalClasses: { [key: string]: boolean } = {};
  @Input() settings;
  @Input() qty;
  @Input() printProduct;
  @Input() file = null;
  @Output() optionSelect = new EventEmitter<any>();
  options = ['laminationOptions', 'ringColor', 'hardCoverFront', 'hardCoverBack'];
  optionSelectd = 0;
  settingOption;
  bindingStock;
  printingGroup;
  groupedOptions;
  isLaminatedCover = false;
  Settings: Settings
  optionMaps = {}

  constructor(private settingService: SettingService,
              private printProductStateService: PrintProductStateService,
              private cartService: CartService) {
  }

  ngOnInit() {
    this.isLaminatedCover = this.printProduct?.product.printingGroup.printSettings?.[Settings.COVER_LAMINATED]
    this.optionSelectd = this.isLaminatedCover ? 0 : 1;
    this.settingOption = this.options[this.optionSelectd];

    this.setMaps()
    this.printProductStateService.printProduct$.subscribe(printProduct => {
      this.printingGroup = printProduct.product.printingGroup;
      this.getGroupedOptions(this.settingOption)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.optionSelectd = this.isLaminatedCover ? 0 : 1;
    this.settingOption = this.options[this.optionSelectd];
  }

  setMaps() {
    for (let option of this.options) {
      if (option === 'laminationOptions') {
        continue
      }
      if (!this.optionMaps[option]) {
        this.optionMaps[option] = new Map<string, number>();
      }
      this.settings[option].options.forEach((_option, index) => {
        this.optionMaps[option].set(_option.value.id, index);
      });
    }
  }

  onSelectOption(i, soldOut = false) {
    if (soldOut) {
      return;
    }
    const prev = this.settings[this.settingOption].selected;
    this.settings[this.settingOption].selected = i + 1;
    this.optionSelect.emit({ setting: this.settingOption, option: this.settings[this.settingOption].selected, prev });
  }

  optionSelected(event) {
    if (!['ringColor', 'hardCoverFront', 'hardCoverBack'].includes(event.setting)) {
      return
    }
    this.settings[event.setting].selected = event.option;
  }


  onSegmentChange(event) {
    this.settingOption = event.detail.value;
    this.optionSelect.emit({ setting: 'segment' });
    this.getGroupedOptions(this.settingOption)
  }

  isSomeExclusive(options) {
    return options.some(op => op.value.exclusive);
  }

  isSoldOut(option, selected) {
    const _printSettings = this.printingGroup.printSettings;
    const isHardCover = ['hardCoverFront', 'hardCoverBack'].includes(this.settingOption);
    const isRingColor = this.settingOption === 'ringColor';
    const stockArray = isHardCover ? this.cartService.bindingStock.covers : this.cartService.bindingStock?.rings;
    const cartBindingUses = this.cartService.cartBindingStockUses;
    let itemStock;
    let itemCartUses;
    let qtyToAdd = 1;    //qty to add
    let soldOut = false;
    let availableStock = 0
    if (_printSettings.grouped) {
      qtyToAdd = selected ? 0 : 1 * this.qty
    } else {
      if (this.file) {
        qtyToAdd = selected ? 0 : 1 * this.qty
      } else {
        qtyToAdd = selected ? 0 : this.printingGroup.files.length * this.qty
      }
    }

    if (_printSettings.grouped) {
      const key = isHardCover ? 'pageSize' : 'diameter';
      const value = isHardCover ? this.printingGroup.printSettings.pageSize : this.printingGroup.binding.ringDiameter;

      itemStock = stockArray.find(el => el.color === option.value.id && el[key] == value);
      availableStock = itemStock?.stock ?? 0

      if (isHardCover && value === PageSize.A5) {
        availableStock += stockArray.find(el => el.color === option.value.id && el[key] == PageSize.A4)?.stock * 2 ?? 0;
      }

      if (isRingColor) {
        itemCartUses = cartBindingUses.rings.find(el => el.color === option.value.id && el.diameter == this.printingGroup.binding.ringDiameter)?.uses ?? 0;
      } else if (isHardCover) {
        itemCartUses = cartBindingUses.covers.find(el => el.color === option.value.id && el.pageSize == this.printingGroup.printSettings.pageSize)?.uses ?? 0;
      }
    } else {
      const files = this.file ? [this.file] : this.printingGroup.files;
      for (const file of files) {
        const key = isHardCover ? 'pageSize' : 'diameter';
        const value = isHardCover ? this.printingGroup.printSettings.pageSize : file.binding?.ringDiameter;

        itemStock = stockArray.find(el => el.color === option.value.id && el[key] == value);
        availableStock = itemStock?.stock ?? 0

        if (isHardCover && value === PageSize.A5) {
          availableStock += stockArray.find(el => el.color === option.value.id && el[key] == PageSize.A4)?.stock * 2 ?? 0;
        }

        if (isRingColor) {
          itemCartUses = cartBindingUses.rings.find(el => el.color === option.value.id && el.diameter == file.binding?.ringDiameter)?.uses ?? 0;
        } else if (isHardCover) {
          itemCartUses = cartBindingUses.covers.find(el => el.color === option.value.id && el.pageSize == this.printingGroup.printSettings.pageSize)?.uses ?? 0;
        }

        if (itemStock && availableStock - itemCartUses - qtyToAdd < 0 && !selected) {
          soldOut = true;
          break;
        }
      }
    }

    return _printSettings.grouped || this.file
      ? itemStock ? availableStock - itemCartUses - qtyToAdd < 0 && !selected : option.value.soldOut
      : soldOut;
  }

  someCoverLaminated() {
    const result = this.printProduct.product.printingGroup.files.some(f => f.coverLaminated);
    if (result && this.settingOption === 'hardCoverFront') {
      this.settingOption = 'ringColor';
      this.optionSelect.emit({ setting: 'segment' });
    }
    return result
  }

  getOptionsId() {
    return this.settingOption === 'ringColor' ? 'rings' : 'covers';
  }

  getGroupedOptions(settingOption) {
    if (settingOption === 'laminationOptions') {
      return
    }

    const noCategory = [];
    const groupedByCategory = {};
    const orderedOptions = cloneDeep(this.settings[settingOption].options).sort((a, b) => a.value.position - b.value.position);
    for (let option of orderedOptions) {
      if (!option.value.category) {
        noCategory.push(option);
      } else {
        if (!groupedByCategory[option.value.category]) {
          groupedByCategory[option.value.category] = [];
        }
        groupedByCategory[option.value.category].push(option);
      }
    }

    this.groupedOptions = { noCategory, groupedByCategory };
  }


  protected readonly Product = Product;

  getLaminationOptions(event: any) {
    const indexType = this.settings[Settings.LAMINATION_TYPE].options.findIndex(el => el.value === event.laminationType)
    const indexWeight = this.settings[Settings.LAMINATION_WEIGHT].options.findIndex(el => el.value === event.laminationWeight)
    this.optionSelect.emit({ setting: Settings.LAMINATION_TYPE, option: indexType + 1 });
    this.optionSelect.emit({ setting: Settings.LAMINATION_WEIGHT, option: indexWeight + 1 });
  }

  getCategoryText(category: string) {
    let result = ''
    switch (category) {
      case 'exclusive':
        result = 'Exclusivas Copyfly:'
        break;
      case 'glitter':
        result = 'Anillas con PURPURINA:'
        break;
      case 'fluorescent':
        result = 'Colores flúor:'
        break;
      case 'standard':
        result = 'Colores estándar:'
        break;
      case 'pastel':
        result = 'Colores pastel:'
        break;
      case 'nature':
        result = 'Colores nature:'
        break;
      default:
        result = ''
        break;
    }
    return result
  }

  getGlobalIndex(id) {
    return this.optionMaps[this.settingOption].get(id)
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }
}

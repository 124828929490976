// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  font-family: "Raleway", sans-serif;
  font-size: 36px;
  color: var(--ion-color-secondary);
  font-weight: 600;
}

p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 22px;
  white-space: pre-line;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm9yZGVycy1ibG9ja2VkLWFsZXJ0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQVlBO0VBQ0Usa0NBQUE7RUFDQSxlQUFBO0VBQ0EsaUNBQUE7RUFDQSxnQkFBQTtBQVhGOztBQWNBO0VBQ0UsZ0RBQUE7RUFDQSxlQUFBO0VBQ0EscUJBQUE7QUFYRiIsImZpbGUiOiJvcmRlcnMtYmxvY2tlZC1hbGVydC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi8vaDEge1xuLy8gIGZvbnQtZmFtaWx5OiBcIkJhcmxvdyBTZW1pIENvbmRlbnNlZFwiLCBzYW5zLXNlcmlmO1xuLy8gIGZvbnQtd2VpZ2h0OiA2MDA7XG4vLyAgZm9udC1zaXplOiAyMnB4O1xuLy99XG4vL1xuLy9wIHtcbi8vICBmb250LWZhbWlseTogXCJCYXJsb3cgU2VtaSBDb25kZW5zZWRcIiwgc2Fucy1zZXJpZjtcbi8vICBmb250LXdlaWdodDogNDAwO1xuLy8gIGZvbnQtc2l6ZTogMTZweDtcbi8vfVxuXG5oMSB7XG4gIGZvbnQtZmFtaWx5OiBcIlJhbGV3YXlcIiwgc2Fucy1zZXJpZjtcbiAgZm9udC1zaXplOiAzNnB4O1xuICBjb2xvcjogdmFyKC0taW9uLWNvbG9yLXNlY29uZGFyeSk7XG4gIGZvbnQtd2VpZ2h0OiA2MDA7XG59XG5cbnAge1xuICBmb250LWZhbWlseTogXCJCYXJsb3cgU2VtaSBDb25kZW5zZWRcIiwgc2Fucy1zZXJpZjtcbiAgZm9udC1zaXplOiAyMnB4O1xuICB3aGl0ZS1zcGFjZTogcHJlLWxpbmU7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/orders-blocked-alert/orders-blocked-alert.component.scss"],"names":[],"mappings":"AAYA;EACE,kCAAA;EACA,eAAA;EACA,iCAAA;EACA,gBAAA;AAXF;;AAcA;EACE,gDAAA;EACA,eAAA;EACA,qBAAA;AAXF;AACA,4/BAA4/B","sourcesContent":["//h1 {\n//  font-family: \"Barlow Semi Condensed\", sans-serif;\n//  font-weight: 600;\n//  font-size: 22px;\n//}\n//\n//p {\n//  font-family: \"Barlow Semi Condensed\", sans-serif;\n//  font-weight: 400;\n//  font-size: 16px;\n//}\n\nh1 {\n  font-family: \"Raleway\", sans-serif;\n  font-size: 36px;\n  color: var(--ion-color-secondary);\n  font-weight: 600;\n}\n\np {\n  font-family: \"Barlow Semi Condensed\", sans-serif;\n  font-size: 22px;\n  white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
